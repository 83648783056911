import { rgba } from 'src/utils/rgbaFromHex';
import { Theme } from '@mui/material/styles';

export const AppColors = {
    primary: '#009789',
    headerTitle: '#e7e8ea',
    screenTitle: '#7b7d7c',
    secondaryTitle: '#3d5282',
    primaryButtonTextColor: '#707070',
    black000: '#000000',
    appInputTitleText: '#080c10',
    borderButton: '#979797',
    signUpHelperButton: '#f27405',
    cancelButton: '#7b7d7c',
    linkButton: '#0087aa',

    facebook: '#6a7ae1',
    phone: '#4ad1ca',

    ellipse: '#4ad1ca',
    ellipseGrey: '#eaebef',

    successLayout: '#4ecec8',
    errorLayout: '#ae1b03',
    marketingText: '#f25c05',
    errorText: '#ae1b03',
    helpIconText: '#908383',
    black: '#3d3d3d',
    appInputTitleBlack: '#080c10',
    barney: '#8514bf',
    palePurple: '#c28adf',
    blue: '#0552cb',
    brightMagenta: '#ff00e7',
    darkSkyBlue: '#52adce',
    cerise: '#ea1260',
    white: '#ffffff',
    veryLightPink: '#f1f1f1',
    whiteTwo: '#fafafa',
    strongPink: '#ea128c',
    brightSkyBlue: '#07c2f3',
    darkGray: '#545454',
    yellow: '#FFC800',
    gray: '#c4c4c4',
    gray4: '#bdbdbd',
    lightGray: '#cccccc',
    lighterGray: '#f3f3f3',
    lighterGray2: 'rgb(245, 245, 245)',
    easyDarkGray: '#8f8f8f',
    facebookMain: '#3B5998',
    orange: '#FF8D07',
    lightGreen: '#19D1A5',
    green: '#10BE36',
    transparent: 'transparent',
    greenButton: '#1fd2b0',
    hoverGreenButton: '#19b99b',
    hoverWhiteButton: '#eae8e8',
    chargeeButton: '#007AFF',
    bmbPrimary: '#0081E0',
    flushOrange: '#FF6C00',
};

export const gradients = {
    gradientLight: (theme: Pick<Theme, 'palette'> & Partial<Theme>) =>
        `linear-gradient(45deg, ${rgba(theme.palette.primary.main, 0.2)} 12.3%, ${rgba(
            theme.palette.primary.main,
            0.2
        )} 23.05%, ${rgba(theme.palette.primary.light, 0.2)} 39.17%, ${rgba(
            theme.palette.primary.light,
            0.2
        )} 55.28%, ${rgba(theme.palette.primary.main, 0.2)} 66.03%)`,
    gradientDark: `linear-gradient(45deg, ${rgba('#838281', 0.2)} 12.3%, ${rgba(
        '#000000',
        0.2
    )} 23.05%, ${rgba('#40403d', 0.2)} 39.17%, ${rgba('#1c1a17', 0.2)} 55.28%, ${rgba(
        '#7e7a78',
        0.2
    )} 66.03%)`,
};
