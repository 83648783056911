import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledCardInfo = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;

    ${AppTypography.regularText};
    color: ${AppColors.cancelButton};
    line-height: 16px;
`;

export const StyledCardTypeNumberSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .svg-icon {
        justify-content: start;
        margin-left: -15px;
        margin-top: -10px;
    }
`;

export const StyledCardActionSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .exp-date {
        align-self: flex-end;
    }
`;

export const StyledCardButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    .delete-button {
        color: ${AppColors.errorText};
        justify-content: flex-end;
    }
`;

export const StyledCreditCard = styled.div`
    display: flex;
    flex-direction: row;
    padding: 25px 13px 22px;
`;

export const StyledNameSection = styled.span`
    opacity: 0.5;
`;

export const StyledLowBalanceTextMessage = styled.div`
    white-space: pre-line;
    ${AppTypography.regularText};
    color: ${AppColors.errorText};
    font-size: 10px;
    line-height: 1.6;
    opacity: 0.5;
`;
