/* eslint-disable no-useless-escape */

// [anySymbol] @ [anySymbol] [dot] [anySymbol] = 5 symbols length minimum
export const NAME_VALIDATOR_REGEX = /([a-zA-Z',. -]){3,255}$/;

// General Email Regex (RFC 5322 Official Standard)
// https://emailregex.com/
export const EMAIL_VALIDATOR_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_NUMBER_VALIDATOR_REGEX = /\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*/;

// According the ISO IEC 7813 the cardholder name length must be 2 to 26 characters
// including first name, last name and spaces.
export const CREDIT_CARD_VALIDATOR_REGEX = /^[-a-zA-Z' ]{2,26}$/;
export const US_PHONE_REGEX =
    /^(\+?(1\s?)((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}|\+?((\([0,2-9][0-9]{2}\))|([0,2-9][0-9]{2}))[\s\-]?[0-9]{3}[\s\-]?[0-9]{4})$/;
