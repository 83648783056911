import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledSubTitleSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledSubTitle = styled.div`
    ${AppTypography.regularText};
    font-size: 20px;
    color: ${AppColors.primaryButtonTextColor};
    align-self: center;
`;

export const StyledBodySection = styled.div`
    margin-top: 11px;
    margin-bottom: 150px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const StyledBankAccounts = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledBodyText = styled.div`
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${AppTypography.regularText};
    color: ${AppColors.screenTitle};
`;

export const StyledConfirmButton = styled.div`
    align-self: center;
`;

export const StyledCancelButton = styled.div`
    align-self: center;
    margin: 25px 0 0;
`;

export const StyledStepBar = styled.div`
    padding-bottom: ${units.p26}px;
`;

export const StyledBorderBetweenCards = styled.div`
    align-self: center;
    width: 97%;
    height: 0;
    opacity: 0.2;
    border: solid 1px #707070;
`;
