import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledRepaymentSchedule = styled.section`
    margin: 30px 0 20px;
`;

export const StyledSubTitle = styled.div`
    ${AppTypography.chargeeRegularText};
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledBorder = styled.div`
    margin: 10px 0;
    height: 0;
    border: solid 0.6px #707070;
`;

export const StyledBody = styled.section`
    margin-top: 20px;
`;

export const StyledTextBlock = styled.div`
    ${AppTypography.chargeeRegularText};
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
`;
