export const transformEnums = (type: string, value: string | undefined) => {
    if (!value) {
        return '';
    }

    const _valueArr = value.split('_');
    const formattedEnumArray = _valueArr.map(el => {
        let _el = el.toLowerCase();
        return _el[0].toUpperCase() + _el.slice(1);
    });
    return formattedEnumArray.join(' ');
};
