import currency from 'currency.js';
import React, { useMemo } from 'react';

import {
    StyledSubtitle,
    StyledBorder,
    StyledChargesComponent,
    StyledTextBlock,
    StyledTotalTextBlock,
} from 'src/components/charges/charges-component.styled';

import { TEXT_VARS } from 'src/i18n/en';

import { FinancingOption, PreparePaymentDTO } from 'src/store/payment/models';

interface ChargesComponentProps {
    paymentResponse: PreparePaymentDTO;
    financingOption?: FinancingOption | null;
    skipLabelSection?: boolean;
    isBrandChargee?: boolean;
    fontSize?: string;
}

export const Charges: React.FC<ChargesComponentProps> = ({
    paymentResponse,
    financingOption,
    skipLabelSection,
    isBrandChargee,
    fontSize,
}) => {
    const totalAmount = useMemo(() => {
        return financingOption ? financingOption.totalPayment : paymentResponse.totalAmount;
    }, [financingOption, paymentResponse.totalAmount]);

    return (
        <>
            {!skipLabelSection && (
                <>
                    <StyledSubtitle $isChargee={!!isBrandChargee}>
                        {TEXT_VARS.COMMON_TEXT.BREAKDOWN_OF_CHARGES}
                    </StyledSubtitle>
                    <StyledBorder />
                </>
            )}
            <StyledChargesComponent>
                <StyledTotalTextBlock fontSize={fontSize}>
                    <StyledTextBlock>
                        <div>{TEXT_VARS.COMMON_TEXT.PURCHASE_AMOUNT}</div>
                        <div>{currency(paymentResponse.amount).format()}</div>
                    </StyledTextBlock>
                    {parseFloat(paymentResponse?.customerFee) != 0 && (
                        <StyledTextBlock>
                            <div>{TEXT_VARS.COMMON_TEXT.SERVICE_FEE}</div>
                            <div>{currency(paymentResponse?.customerFee).format()}</div>
                        </StyledTextBlock>
                    )}
                    {financingOption && (
                        <>
                            {financingOption.surcharge != 0 && (
                                <StyledTextBlock>
                                    <div>{TEXT_VARS.COMMON_TEXT.SURCHARGE_FEE}</div>
                                    <div>{currency(financingOption.surcharge).format()}</div>
                                </StyledTextBlock>
                            )}
                            {financingOption.financingFee != 0 && (
                                <StyledTextBlock>
                                    <div>{TEXT_VARS.COMMON_TEXT.FINANCING_FEE}</div>
                                    <div>{currency(financingOption.financingFee).format()}</div>
                                </StyledTextBlock>
                            )}
                        </>
                    )}
                    <StyledTextBlock className="total-charges">
                        <div>{TEXT_VARS.COMMON_TEXT.TOTAL_CHARGES}</div>
                        <div>{currency(totalAmount).format()}</div>
                    </StyledTextBlock>
                </StyledTotalTextBlock>
            </StyledChargesComponent>
        </>
    );
};
