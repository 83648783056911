import currency from 'currency.js';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

import { FinancingOption } from 'src/store/payment/models';

import {
    StyledRepaymentSchedule,
    StyledBorder,
    StyledSubTitle,
    StyledTextBlock,
    StyledBody,
} from './repayment-schedule.styled';

export const RepaymentSchedule = ({ financingOption }: { financingOption: string }) => {
    const decodedFinancingOption: FinancingOption = jwtDecode(financingOption);

    const { installments } = decodedFinancingOption;

    return (
        installments && (
            <StyledRepaymentSchedule>
                <StyledSubTitle>Repayment schedule</StyledSubTitle>
                <StyledBorder />
                <StyledBody>
                    {installments &&
                        installments.map((installment, index) => {
                            return (
                                <StyledTextBlock key={installment.date + index}>
                                    <div>{dayjs(installment.date).format('MMM DD, YYYY')}</div>
                                    <div>{currency(installment.amount).format()}</div>
                                </StyledTextBlock>
                            );
                        })}
                </StyledBody>
            </StyledRepaymentSchedule>
        )
    );
};
