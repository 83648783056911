import { Card, CardState } from 'src/store/cards/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const INITIAL_STATE: CardState = {};

const cardReducer = createSlice({
    name: 'CardState',
    initialState: INITIAL_STATE,
    reducers: {
        clearCards: () => INITIAL_STATE,
        saveSelectedCard: (state, action: PayloadAction<Card | undefined>) => {
            state.selectedCard = action.payload;
        },
    },
});

export const { clearCards, saveSelectedCard } = cardReducer.actions;
export default cardReducer.reducer;
