import { AppTypography } from 'src/theme/app-typography';
import styled from '@emotion/styled';


export const StyledTitle = styled.div`
    margin: 35px 0;
    ${AppTypography.chargeeRegularText};
    font-size: 21px;
    font-weight: bold;
    text-align: center;
`;

export const StyledTextSection = styled.div`
    margin-top: 30px;
`;

export const StyledText = styled.div`
    margin: 30px 0;
    ${AppTypography.chargeeRegularText};
    font-weight: normal;
    text-align: center;
    line-height: 1.6;
`;
