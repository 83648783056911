import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.PROD,
    firebaseConfig: {
        apiKey: 'AIzaSyCe94bJgu7mlr_M1ZjozFO8iwx20FrYvxE',
        authDomain: 'chargee-prod.firebaseapp.com',
        databaseURL: 'https://chargee-prod.firebaseio.com',
        projectId: 'chargee-prod',
        storageBucket: 'chargee-prod.appspot.com',
        messagingSenderId: '789872572438',
        appId: '1:789872572438:web:4a80dee1797fb7ea61dd37',
        measurementId: 'G-GE7B96P64T',
    },
    siteId: 'chargee-prod',
    baseApiUrl: 'https://api.chargee.co',
    fiServeWidgetBaseUrl: 'https://ais-ui.fiservapps.com',
    showBnpl: false,
    sentryConfig: {
        dsn: 'https://c6da10034243471c8169bbb62c329db6@o503848.ingest.sentry.io/5589529',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.1,
    },
    iOSStore: 'https://apps.apple.com/us/app/chargee-checkouts/id6443721932',
    googleStore: 'https://play.google.com/store/apps/details?id=co.chargee.app&pli=1',
    branchKey: 'key_live_kAaNW2VhWn5C9Lb3waOPTccjEFp11kbY',
};

export default config;
