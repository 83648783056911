import { useEffect, useRef } from 'react';

export const useMountedRef = () => {
    const state = useRef(false);
    useEffect(() => {
        state.current = true;

        return () => {
            state.current = false;
        };
    }, []);
    return state;
};
