import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.DEV,
    firebaseConfig: {
        apiKey: 'AIzaSyAcZ6G71cvQqv5CQABuCd-EqdTkT8sefMA',
        authDomain: 'chargee-devel.firebaseapp.com',
        databaseURL: 'https://chargee-devel.firebaseio.com',
        projectId: 'chargee-devel',
        storageBucket: 'chargee-devel.appspot.com',
        messagingSenderId: '559746683271',
        appId: '1:559746683271:web:058e9db31946dff325c322',
        measurementId: 'G-3G2E0FLBLZ',
    },
    siteId: 'chargee-devel',
    baseApiUrl: 'https://api-dev.chargee.co',
    fiServeWidgetBaseUrl: 'https://ais-ui-uat.fiservapps.com',
    showBnpl: true,
    iOSStore: 'https://apps.apple.com/us/app/billmybank/id6444312258',
    googleStore: 'https://play.google.com/store/apps/details?id=co.chargee.app.dev',
    branchKey: 'key_test_jzkP5ZPn2a4A6OlYwiRVBknpyzhX1adO',
};

export default config;
