import * as jose from 'jose';

export const getEncryptedData = async ({
    publicKey,
    cardNumber,
    expMonth,
    expYear,
}: {
    publicKey: string;
    cardNumber: string;
    expMonth: number;
    expYear: number;
}) => {
    const algorithm = 'RSA-OAEP';
    const x509 = `-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`;
    const ecPublicKey = await jose.importSPKI(x509, algorithm);

    return await new jose.CompactEncrypt(
        new TextEncoder().encode(
            `{"cardNumber":"${cardNumber}","expMonth":"${expMonth}","expYear":"${expYear}"}`
        )
    )
        .setProtectedHeader({ alg: algorithm, enc: 'A256GCM' })
        .encrypt(ecPublicKey);
};
