import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import { getAuth, onAuthStateChanged, setPersistence, inMemoryPersistence } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import React from 'react';
import { firebaseApp } from 'src';

import 'src/assets/css/firebase/fb.css';

import { signIn } from 'src/store/user/actions.thunks';

import StyledFirebaseAuth from './components/StyledFirebaseAuth';
import { StyledTitleWrapper } from './firebase-sign-in.styled';

class FirebaseSignIn extends React.Component {
    uiConfig = {
        signInFlow: 'popup',

        signInOptions: [
            {
                provider: EmailAuthProvider.PROVIDER_ID,
                fullLabel: 'Continue with email',
            },
            {
                provider: GoogleAuthProvider.PROVIDER_ID,
                customParameters: {
                    // Forces account selection even when one account
                    // is available.
                    prompt: 'select_account',
                },
                fullLabel: 'Continue with Google',
            },
            {
                provider: 'apple.com',
                fullLabel: 'Continue with Apple',
            },
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false,
        },
        credentialHelper: 'none',
        tosUrl: 'https://billmybank.com/terms-of-use/',
        privacyPolicyUrl: 'https://billmybank.com/privacy-policy/',
    };

    private unregisterAuthObserver: firebase.Unsubscribe | undefined;
    private fbAuth = getAuth(firebaseApp);

    componentDidMount() {
        this.unregisterAuthObserver = onAuthStateChanged(this.fbAuth, user => {
            if (user) {
                setPersistence(this.fbAuth, inMemoryPersistence).then(() => {
                    firebase.auth().currentUser;
                    signIn(user as firebase.User);
                });
            }
        });
    }
    componentWillUnmount() {
        this.unregisterAuthObserver && this.unregisterAuthObserver();
    }

    render() {
        return (
            <StyledTitleWrapper>
                <StyledFirebaseAuth firebaseAuth={this.fbAuth} uiConfig={this.uiConfig} />
            </StyledTitleWrapper>
        );
    }
}

export default FirebaseSignIn;
