import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AccountComponent } from 'src/components/account/account-component.tsx';
import { Alerts } from 'src/components/alerts';
import { AppButton } from 'src/components/button';
import { Loader } from 'src/components/loader';
import { PrivateAndSecure } from 'src/components/private-and-secure';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { TextSection } from 'src/screens/consent-confirmation/consent-confirmation.styled';

import { selectSelectedBankAccount } from 'src/store/accounts/selectors';
import { setPaymentLinkConsent } from 'src/store/payment/actions.thunks';
import { selectPrepayment } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { StyledButtonSection, StyledConfirmButton } from './consent-confirmation.styled';

export const ConsentConfirmation = () => {
    const route = useAppRoute();
    const bankAccount = useSelector(selectSelectedBankAccount);
    const prepayment = useSelector(selectPrepayment);

    const [setConsentAction, [isConsentLoading], [consentError]] = useThunkAction(setPaymentLinkConsent);

    const merchantName = prepayment?.merchantName;

    const handleContinueButtonClick = useCallback(() => {
        if (!prepayment) {
            return;
        }

        setConsentAction({ paymentLinkToken: prepayment.shortCode, isConsent: true }).then((r: any) => {
            if (!r.success) {
                return;
            }

            route(AppRoutes.downloadApp);
        });
    }, [prepayment, route, setConsentAction]);

    const handleAccountChange = useCallback(() => {
        route(AppRoutes.customerSelectYourBankAccount);
    }, [route]);

    const text = () => {
        return `${TEXT_VARS.COMMON_TEXT.I_AUTHORISE_1} ${merchantName} ${TEXT_VARS.COMMON_TEXT.I_AUTHORISE_2} ${merchantName} ${TEXT_VARS.COMMON_TEXT.I_AUTHORISE_3}`;
    };

    return (
        <StyledPageWithPaddingContainer>
            <Loader isShowing={isConsentLoading} />
            {consentError && <Alerts alertType="error" message={consentError} />}

            <StyledButtonSection>
                {bankAccount && prepayment && (
                    <AccountComponent
                        bankAccount={bankAccount}
                        errorMessage={undefined}
                        handleAccountChange={handleAccountChange}
                        totalAmount={prepayment.amount}
                    />
                )}

                <TextSection>{text()}</TextSection>

                <StyledConfirmButton>
                    <AppButton appButtonType="Continue" onClick={handleContinueButtonClick}>
                        {TEXT_VARS.BUTTON.CONFIRM}
                    </AppButton>
                </StyledConfirmButton>
            </StyledButtonSection>
            <PrivateAndSecure />
        </StyledPageWithPaddingContainer>
    );
};
