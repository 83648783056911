import React from 'react';
import { Card } from 'src/store/cards/models';
import { CreditCardIcon } from 'src/screens/confirmation/components/credit-card-icon';
import { TEXT_VARS } from 'src/i18n/en';
import {
    StyledCardData,
    StyledCardComponent,
    StyledTextBlock,
    StyledBorder,
    StyledLinkButton,
    StyledRowBlock,
    StyledSubtitle,
    StyledSubtitleSection,
} from 'src/screens/confirmation/components/card-component.styled';
import classNames from 'classnames';

interface CardComponentProps {
    creditCard: Card;
    handleCardChange(): void;
    errorMessage?: string;
}

export const CardComponent: React.FC<CardComponentProps> = ({
    creditCard,
    handleCardChange,
    errorMessage,
}) => {
    return (
        <>
            <StyledRowBlock>
                <StyledSubtitleSection>
                    <StyledSubtitle className={classNames(errorMessage ? 'error' : '')}>
                        {TEXT_VARS.COMMON_TEXT.CARD_NUMBER}
                    </StyledSubtitle>
                </StyledSubtitleSection>
                <StyledLinkButton onClick={handleCardChange}>
                    {TEXT_VARS.COMMON_TEXT.CHANGE.toLowerCase()}
                </StyledLinkButton>
            </StyledRowBlock>
            <StyledBorder />
            <StyledCardComponent>
                {<CreditCardIcon cardType={creditCard.cardType} />}
                {
                    <StyledCardData>
                        <StyledTextBlock className={classNames(errorMessage ? 'error' : '')}>
                            <div>{TEXT_VARS.COMMON_TEXT.CARD_NUMBER}</div>
                            <div>{creditCard.redactedNumber.slice(creditCard.redactedNumber.length - 8)}</div>
                        </StyledTextBlock>
                        <StyledTextBlock className={classNames(errorMessage ? 'error' : '')}>
                            <div>{TEXT_VARS.COMMON_TEXT.EXPIRATION_DATE}</div>
                            <div>{`${creditCard.expMonth}/${creditCard.expYear}`}</div>
                        </StyledTextBlock>
                        {errorMessage && (
                            <StyledTextBlock className={'error-message'}>{errorMessage}</StyledTextBlock>
                        )}
                    </StyledCardData>
                }
            </StyledCardComponent>
        </>
    );
};
