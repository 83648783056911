import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors.ts';
import { AppTypography } from 'src/theme/app-typography.ts';
import { units } from 'src/theme/units.ts';

export const StyledTitle = styled.div`
    margin: ${units.p42}px 0 ${units.p17}px;
    .styled-title {
        ${AppTypography.appInputTitle};
        font-size: 20px;
        font-weight: normal;
    }
`;

export const TextBody = styled.div`
    display: flex;
    flex-direction: column;
    ${AppTypography.regularText};
`;

export const StyledInfoText = styled.div`
    margin: 10px 0 27px;
    ${AppTypography.confirmationInfoText};

    .title {
        font-weight: bold;
    }
`;

export const StyledTermsConditions = styled.div`
    margin-bottom: 25px;
    text-align: center;

    .terms-and-conditions {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        ${AppTypography.regularText};
        color: ${AppColors.screenTitle};
    }
`;

export const StyledConfirmButton = styled.div`
    align-self: center;
`;

export const StyledCancelButton = styled.div`
    align-self: center;
    margin: 12px 0 0;
`;

export const StyledPaymentCode = styled.div`
    padding: 10px;
    ${AppTypography.chargeeRegularText};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #e7e8e9;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;

    span:first-child {
        margin-right: 5px;
    }
`;

export const StyledSpan = styled.div`
    ${AppTypography.finOptionsRegularText};
    line-height: 1.3;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-right: 5px;
`;

export const StyledSubTitle = styled.div`
    ${AppTypography.chargeeRegularText};
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledBorder = styled.div`
    margin: 10px 0;
    height: 0;
    border: solid 0.6px #707070;
`;

export const StyledTextBlock = styled.div`
    .MuiFormControlLabel-label {
        ${AppTypography.regularText};
        color: ${AppColors.black000};

        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        width: 100%;
    }
`;

export const StyledLinkButton = styled.a`
    text-decoration: none;
    align-self: center;
    color: ${AppColors.bmbPrimary};
    font-size: 14px;
    cursor: pointer;
`;

export const StyledPrivate = styled.div`
    margin-top: 5px;
`;
