import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.STAGE,
    firebaseConfig: {
        apiKey: 'AIzaSyBAnLXV4z3kP-4nFD1-IJQGy4yNzB3xsc8',
        authDomain: 'chargee-staging.firebaseapp.com',
        databaseURL: 'https://chargee-staging.firebaseio.com',
        projectId: 'chargee-staging',
        storageBucket: 'chargee-staging.appspot.com',
        messagingSenderId: '377606908120',
        appId: '1:377606908120:web:114af86d5c312bdb4c8fad',
        measurementId: 'G-N068HP65TC',
    },
    siteId: 'chargee-staging',
    baseApiUrl: 'https://api-stage.chargee.co',
    fiServeWidgetBaseUrl: 'https://ais-ui-uat.fiservapps.com',
    showBnpl: true,
    sentryConfig: {
        dsn: 'https://c6da10034243471c8169bbb62c329db6@o503848.ingest.sentry.io/5589529',
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
    },
    iOSStore: 'https://apps.apple.com/us/app/chargee-checkouts/id6444312135',
    googleStore: 'https://play.google.com/store/apps/details?id=co.chargee.app.stage',
    branchKey: 'key_test_jzkP5ZPn2a4A6OlYwiRVBknpyzhX1adO',
};

export default config;
