import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledTitle = styled.div`
    margin: 35px 0;
    ${AppTypography.chargeeRegularText};
    font-size: 21px;
    font-weight: bold;
    text-align: center;
`;

export const StyledPurchaseAmount = styled.div`
    padding: 10px 0;
    ${AppTypography.chargeeBrandName};
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${AppColors.lighterGray2};
    border-radius: 10px;

    .pm-title {
        font-size: 18px;
    }

    .pm-sum {
        font-size: 21px;
    }
`;

export const StyledFinOptionsWrapper = styled.div`
    padding: 40px 0 20px;
    margin-top: 10px;
`;

export const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;
