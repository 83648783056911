import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from 'src/store/root-reducer.models';

export const getUserState = (state: ApplicationState) => state.user;
export const getRouterState = (state: ApplicationState) => state.router;

export const selectSignInState = createSelector(getUserState, ({ authenticated }) => authenticated);
export const selectSignInUser = createSelector(getUserState, ({ user }) => user);
export const selectUserName = createSelector([selectSignInUser], user => {
    return user && user.displayName;
});
export const selectUserEmail = createSelector([selectSignInUser], user => {
    return user && user.email;
});
export const selectUserPhone = createSelector([selectSignInUser], user => {
    return user && user.phoneNumber;
});

export const selectCustomer = createSelector(getUserState, ({ customer }) => customer);
export const selectCustomerId = createSelector(getUserState, ({ customer }) => customer && customer.id);

export const selectBankAccounts = createSelector([selectCustomer], customer => {
    return customer && customer.accounts;
});

export const selectCards = createSelector([selectCustomer], customer => {
    return customer && customer.cards;
});

export const selectDefaultBankAccount = createSelector(
    getUserState,
    ({ customer }) => customer && customer.defaultBankAccount
);

export const selectDefaultCreditCard = createSelector(
    getUserState,
    ({ customer }) => customer && customer.defaultCreditCard
);

export const selectQueryParams = createSelector(getUserState, ({ queryParams }) => queryParams);

export const selectMerchant = createSelector(getUserState, ({ merchant }) => merchant);

export const selectConnectWidgetData = createSelector(
    getUserState,
    ({ connectWidgetData }) => connectWidgetData
);

export const selectPaymentLinkData = createSelector(getUserState, ({ paymentLink }) => paymentLink);
export const selectResponseData = createSelector(getUserState, ({ response }) => response);

export const selectPrepayment = createSelector(getUserState, ({ prepayment }) => prepayment);
export const selectIsCustomerNew = createSelector(getUserState, ({ isCustomerNew }) => isCustomerNew);
export const selectJWTToken = createSelector(getUserState, ({ generatedJWTToken }) => generatedJWTToken);
export const selectRouterLocation = createSelector(getRouterState, router => router as any);
export const selectUserAgreements = createSelector(getUserState, ({ agreements }) => agreements?.agreement);
export const selectAcceptedAgreementsStatus = createSelector(
    getUserState,
    ({ agreements }) => agreements?.accepted
);
export const selectIsTermsAndConditionsDisplayed = createSelector(getUserState, ({ agreements }) => {
    return agreements?.agreement?.docUrl && agreements.agreement.docVersion;
});
export const selectFiservToken = createSelector(getUserState, ({ fiservSessionObj }) => fiservSessionObj);
export const selectFinancingOptions = createSelector(
    getUserState,
    ({ financingOptions }) => financingOptions
);
