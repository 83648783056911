import { DocType } from 'src/store/user/models';

export class UserEndpoints {
    static customers = '/api/customers';
    static getCurrentCustomer = '/api/customers/current';
    static customerCreditCard = (id: string) => `/api/customers/${id}/cards`;
    static customerCreditCardDelete = (customerId: string, cardId: string) =>
        `/api/customers/${customerId}/cards/${cardId}/`;
    static customerBankAccount = (id: string, bankAccountId: string) =>
        `/api/customers/${id}/bank-accounts/${bankAccountId}`;

    static getCustomerBankAccount = (id: string) => `/api/customers/${id}/bank-accounts`;

    static aggregatedBankAccounts = (id: string, memberId: string) =>
        `/api/customers/${id}/bank-accounts/member/${memberId}`;
    static accountAggregationStatus = (id: string, memberId: string) =>
        `/api/customers/${id}/bank-accounts/member/${memberId}/status`;
    static connectWidget = (id: string) => `/api/customers/${id}/bank-accounts/widget`;
    static fiservSessionId = (id: string) => `/api/customers/${id}/bank-accounts/sessionId`;
    static checkAccountUpdate = (id: string, requestId: string) =>
        `/api/customers/${id}/bank-accounts/checkAccountUpdateCompleted?requestId=${requestId}`;
    static getIdpAccounts = (id: string, fiLoginAcctId: string) =>
        `/api/customers/${id}/idp-accounts?fiLoginAcctId=${fiLoginAcctId}&hideAdded=true`;
    static addBankAccount = (bankAccountId: string, customerId: string) =>
        `/api/customers/${customerId}/bank-accounts/${bankAccountId}`;
    static ledgerAccount = (id: string) => `/api/customers/${id}/ledger-account`;
}

export class InstitutionEndpoints {
    static institutions = '/api/institutions';
    static getInstitutionsByName = (institutionCode: string) => `/api/institutions/?name=${institutionCode}`;
    static getInstitutionCredentials = (institutionCredentials: string) =>
        `/api/institutions/${institutionCredentials}/credentials`;
}

export class PaymentEndpoints {
    static payments = '/api/payments';
    static preparePayments = '/api/payments/prepare';
    static paybackPayments = '/api/payments/payback';
    static paymentLink = (token: string) => `/api/paymentLinks/${token}`;
    static paymentLinkStatus = (token: string) => `/api/paymentLinks/${token}/status`;
    static cancelPayment = `/api/cancelPayment`;
    static financingOptions = ({
        amount,
        mode,
        customerId,
        bnplMerchantId,
        bankAccountId,
    }: {
        amount: string;
        mode: string;
        customerId?: string;
        bnplMerchantId?: string;
        bankAccountId?: string;
    }) => {
        const query = new URLSearchParams({
            amount,
            mode,
            ...(customerId && { customerId }),
            ...(bnplMerchantId && { bnplMerchantId }),
            ...(bankAccountId && { bankAccountId }),
        });
        return `/api/financingProducts/financingOptions?${query.toString()}`;
    };
}

export class MerchantEndpoints {
    static currentMerchant = `/api/merchants/current`;
}

export class PrePaymentResource {
    static prepayment = `/api/prepayments`;
    static prepaymentShortCode = (shortCode: string) => `/api/prepayments/${shortCode}`;
}

export class SecurityResource {
    static generateJWTToken = '/api/security/authenticate';
}

export class AgreementResource {
    static agreements = '/api/user/agreements';
    static agreementsDocType = (docType: DocType) => `/api/user/agreements/${docType}`;
}

export class PaymentLinkResource {
    static consent = (token: string) => `/api/paymentLinks/${token}/consent`;
}

export class ChangeResource {
    static pubKey = '/api/change/pubKey';
    static getChange = (customerId: string) => `/api/change/customer/${customerId}`;
}

export class CustomerResource {
    static mergeCustomer = (virtualCustomerId: string) => `/api/customers/merge/${virtualCustomerId}`;
}

export class DefaultResource {
    static widgetSettings = '/api/defaults/widgetSettings';
}

export class BrandResource {
    static infer = (id: string) => `/api/brands/infer?merchantId=${id}`;
    static brands = (page: number, size: number, id?: string, filtering?: string, sorting?: string) => {
        let url = `/api/brands?page=${page}&size=${size}${sorting ? `&sort=${sorting}` : ''}`;
        if (filtering) url += `&${filtering}`;

        return url;
    };
}
