import styled from '@emotion/styled';

import { units } from 'src/theme/units';

export const PagePaddingHorizontal = units.p36_5;

export const StyledPageWithPaddingContainer = styled('main', {
    shouldForwardProp: prop => prop !== '$isPointerEventsDisabled',
})<{ $isPointerEventsDisabled?: boolean }>`
    padding: 0 ${PagePaddingHorizontal}px ${units.p16}px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-self: center;
    width: 302px;

    pointer-events: ${props =>
        props.$isPointerEventsDisabled ? 'none' : 'auto'}; // pointer-events not supported below IE11
`;
