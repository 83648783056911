import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const TextSection = styled.div`
    ${AppTypography.chargeeRegularThinText};
    font-size: 18px;
    text-align: center;
    white-space: break-spaces;
    margin-bottom: 40px;
`;

export const StyledButtonSection = styled.div`
    margin-top: 100px;
`;

export const StyledConfirmButton = styled.div`
    align-self: center;
    margin: 25px 0 0;
`;
