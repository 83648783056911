import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';
import { Loader } from 'src/components/loader';
import { TEXT_VARS } from 'src/i18n/en';
import currency from 'currency.js';
import { AppButton } from 'src/components/button';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCustomer,
    selectJWTToken,
    selectPrepayment,
    selectQueryParams,
} from 'src/store/user/selectors';
import { AppRoutes } from 'src/router/config';
import { useThunkAction } from 'src/hooks/useThunkAction';
import { getFinancingOptions, preparePayment } from 'src/store/payment/actions.thunks';
import { selectSelectedBankAccount } from 'src/store/accounts/selectors';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { useAppRoute } from 'src/hooks/useAppRoute';
import {
    StyledFinOptionsWrapper,
    StyledButtonSection,
    StyledPurchaseAmount,
    StyledTitle,
} from './re-financing.styled';
import { BackButton } from 'src/components/back-button';
import { FinancingOption, PaymentMode } from 'src/store/payment/models';
import { saveFinancingOption } from 'src/store/payment/reducer';
import { FinancingOptionsRadio } from 'src/components/financing-options-radio';
import { selectWelcomeMode } from 'src/store/app/selectors';
import { jwtDecode } from 'jwt-decode';
import { setFirstBARender } from 'src/store/app/reducer';
import { ErrorPopupRedesign } from 'src/components/error-popup-redesign';
import WarningBlack from 'src/assets/img/warning_black_24dp.svg?react';

export const ReFinancing: React.FC = () => {
    const isMountedRef = useMountedRef();
    const route = useAppRoute();
    const dispatch = useDispatch();

    const queryParamsSelector = useSelector(selectQueryParams);
    const queryParams = useSelector(selectQueryParams);
    const bankAccount = useSelector(selectSelectedBankAccount);
    const customerSelector = useSelector(selectCustomer);
    const isWelcomeFlow = useSelector(selectWelcomeMode);
    const selectedBankAccount = useSelector(selectSelectedBankAccount);
    const generatedJWTToken = useSelector(selectJWTToken);
    const prepayment = useSelector(selectPrepayment);

    const [financingOption, setFinancingOption] = useState<string>('');
    const [financingOptions, setFinancingOptions] = useState<any>();
    const [isHiccupDisplayed, setIsHiccupDisplayed] = useState(false);

    const [_preparePaymentAction, [isPreparePaymentLoading], [preparePaymentError, setPreparePaymentError]] =
        useThunkAction(preparePayment);

    const [financingOptionsAction, [isFinancingOptionsLoading], [financingOptionsError]] =
        useThunkAction(getFinancingOptions);

    const isDirectPayment = useMemo(() => {
        if (!financingOption) {
            return;
        }

        const decodedFinOption: FinancingOption = jwtDecode(financingOption);
        return !decodedFinOption.financingProductId;
    }, [financingOption]);

    const handleContinueButtonClick = useCallback(async () => {
        if (!bankAccount) {
            return;
        }

        if (!queryParams) {
            return;
        }

        dispatch(saveFinancingOption(financingOption));

        _preparePaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                merchantOrderId: queryParams.orderId || prepayment?.merchantOrderId || '',
                bankAccountId: bankAccount.id,
                type: isDirectPayment ? 'SIMPLE' : 'FINANCING',
                ...(!isDirectPayment && { financingOption }),
            },
        }).then((r: any) => {
            if (!isMountedRef.current) {
                return;
            }

            if (!r.success) {
                if (r.error.message.response && r.error.message.response?.status >= 500) {
                    setPreparePaymentError(TEXT_VARS.ERRORS.SERVER_ERROR);
                }

                if (r.error.message.response && r.error.message.response?.status == 423) {
                    setPreparePaymentError(undefined);
                    setIsHiccupDisplayed(true);
                }

                return;
            }

            r.payload.cardAuthMode === 'NEVER'
                ? route(AppRoutes.customerConfirmation)
                : route(AppRoutes.customerSelectYourCard);
        });
    }, [
        _preparePaymentAction,
        bankAccount,
        dispatch,
        financingOption,
        isDirectPayment,
        isMountedRef,
        prepayment?.merchantOrderId,
        queryParams,
        route,
        setPreparePaymentError,
    ]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
    }, []);

    useEffect(() => {
        if (!queryParamsSelector) {
            return;
        }

        if (!generatedJWTToken) {
            return;
        }

        financingOptionsAction({
            amount: queryParamsSelector.amount,
            paymentMode: queryParamsSelector.paymentMode as PaymentMode,
            ...(customerSelector && { customerId: customerSelector.id }),
            ...(isWelcomeFlow && selectedBankAccount && { bankAccountId: selectedBankAccount.id }),
        }).then((r: any) => {
            if (!r.success) {
                return;
            }

            setFinancingOptions(r.payload);
        });
    }, [
        customerSelector,
        financingOptionsAction,
        isWelcomeFlow,
        queryParamsSelector,
        selectedBankAccount,
        generatedJWTToken,
    ]);

    const errorText: string | undefined = useMemo(() => {
        return financingOptionsError || preparePaymentError;
    }, [financingOptionsError, preparePaymentError]);

    useEffect(() => {
        dispatch(setFirstBARender(false));
    }, [dispatch]);

    return (
        <StyledPageWithPaddingContainer>
            <Loader isShowing={isPreparePaymentLoading || isFinancingOptionsLoading} />
            <BackButton routeUrl={AppRoutes.customerSelectYourBankAccount} />

            <StyledTitle>
                <span>{TEXT_VARS.TITLE.BUY_NOW_PAY_LATER}</span>
            </StyledTitle>
            {queryParamsSelector && (
                <StyledPurchaseAmount>
                    <span className={'pm-title'}>Purchase Amount</span>
                    <span className={'pm-sum'}>{currency(queryParamsSelector.amount).format()}</span>
                </StyledPurchaseAmount>
            )}
            <StyledFinOptionsWrapper>
                <FinancingOptionsRadio
                    financingOptions={financingOptions}
                    financingOption={financingOption}
                    setFinancingOption={setFinancingOption}
                />
            </StyledFinOptionsWrapper>

            <StyledButtonSection>
                <AppButton
                    appButtonType={'Continue'}
                    onClick={handleContinueButtonClick}
                    disabled={!financingOption}
                >
                    {TEXT_VARS.BUTTON.CONTINUE}
                </AppButton>
            </StyledButtonSection>
            {!!errorText && (
                <InfoActionLayout
                    type="error"
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    bodyText={errorText}
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                />
            )}
            {isHiccupDisplayed && (
                <ErrorPopupRedesign
                    Icon={WarningBlack}
                    labelText={TEXT_VARS.COMMON_TEXT.OOPS_HICCUP}
                    bodyText={TEXT_VARS.COMMON_TEXT.WE_UNABLE_PROCESS_PAYMENT}
                />
            )}
        </StyledPageWithPaddingContainer>
    );
};
