import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledAccountInfo = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;

    ${AppTypography.regularText};
    color: ${AppColors.cancelButton};
    line-height: 16px;

    .delete-button {
        color: ${AppColors.errorText};
        justify-content: flex-end;
    }
`;

export const StyledAccountNameNumberSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledAccountTypeSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    opacity: 0.5;
`;

export const StyledAccountBottomSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledBankAccount = styled('div', {
    shouldForwardProp: prop => prop !== '$isUpToDate',
})<{ $isUpToDate: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 25px 13px 22px;
    opacity: ${props => (props.$isUpToDate ? 1 : 0.5)};
`;

export const StyledNameSection = styled.span`
    display: inline-flex;
    align-items: center;
    opacity: 0.5;
`;

export const StyledLowBalanceTextMessage = styled.div`
    white-space: pre-line;
    ${AppTypography.regularText};
    color: ${AppColors.errorText};
    font-size: 10px;
    line-height: 1.6;
    opacity: 0.5;
`;

export const StyledError = styled.span`
    color: ${AppColors.errorText};
`;
