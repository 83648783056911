import { TEXT_VARS } from 'src/i18n/en';
import { StyledSecure, StyledSecureSection } from './private-and-secure.styled';
import LockIconSVG from 'src/assets/img/mui-lock.svg?react';

export const PrivateAndSecure = () => {
    return (
        <StyledSecureSection>
            <LockIconSVG />
            <StyledSecure>{TEXT_VARS.COMMON_TEXT.PRIVATE_AND_SECURE}</StyledSecure>
        </StyledSecureSection>
    );
};
