import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AppColors, gradients } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

import { rgba } from 'src/utils/rgbaFromHex';

const gradientLite = `linear-gradient(
    45deg,
    ${rgba('#ff6700', 0.2)} 12.3%,
    ${rgba('#d27b1d', 0.2)} 23.05%,
    ${rgba('#d2a535', 0.2)} 39.17%,
    ${rgba('#eca651', 0.2)} 55.28%,
    ${rgba('#e07d2b', 0.2)} 66.03%
)`;

const gradientTandCLite = `linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(31,210,176,1) 0%, rgba(178,255,240,1) 100%);`;
const gradientTandCCancelLite = `linear-gradient(36deg, rgba(255,255,255,1) 37%, rgba(177,177,177,1) 100%);`;

const StyledBtn = (props: ButtonProps) => <Button variant="contained" {...props} />;

export const StyledSignWithGoogleButton = styled(StyledBtn)`
    ${AppTypography.primaryButtonText};
    background: ${AppColors.white};
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border: solid 1px ${AppColors.borderButton};
    border-radius: 10px;
    width: 302px;
    height: 46px;
    align-self: center;

    .MuiButton-label {
        z-index: 1;
    }
`;

export const StyledSignWithFacebookButton = styled(StyledBtn)`
    ${AppTypography.primaryButtonText};
    color: ${AppColors.white};
    background: ${AppColors.facebook};
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border-radius: 10px;
    width: 302px;
    height: 46px;
    align-self: center;

    .MuiButton-label {
        z-index: 1;
    }
`;

export const StyledSignWithEmailButton = styled(StyledBtn)`
    ${AppTypography.primaryButtonText};
    color: ${AppColors.white};
    background: ${AppColors.phone};
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border-radius: 10px;
    width: 302px;
    height: 46px;
    align-self: center;

    .MuiButton-label {
        z-index: 1;
    }
`;

export const StyledSignWithPhoneButton = styled(StyledBtn)`
    ${AppTypography.primaryButtonText};
    color: ${AppColors.white};
    background: ${AppColors.phone};
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border-radius: 10px;
    width: 302px;
    height: 46px;
    align-self: center;

    .MuiButton-label {
        z-index: 1;
    }
`;

export const StyledContinueButton = styled(Button)`
    ${AppTypography.primaryButtonText};
    background: ${(props: any) =>
        props.theme.brandTheme?.brandProperties?.primaryColor || props.theme.palette.primary.main};
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border-radius: 100px;
    width: 302px;
    height: 60px;
    align-self: center;

    ${AppTypography.chargeeRegularText}
    color: ${AppColors.white};
    font-size: 21px;

    .MuiButton-label {
        z-index: 1;
    }

    span {
        width: fit-content;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }

    &:hover {
        background: ${props => props.theme.palette.primary.main};

        &:not(:disabled) {
            &:before {
                opacity: 1;
            }
        }
    }

    &:disabled {
        background: ${AppColors.ellipseGrey};
        color: ${AppColors.white};
    }
`;

export const StyledErrorReDesignButton = styled(Button)`
    ${AppTypography.chargeeRegularText};
    background: white;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border-radius: 30px;
    width: 290px;
    height: 60px;
    align-self: center;

    font-size: 21px;

    // prettier-ignore
    .MuiButton-label {
        z-index: 1;
    }

    span {
        width: fit-content;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }

    &:hover {
        background: ${({ theme }: any) => gradients.gradientLight(theme)};

        &:not(:disabled) {
            &:before {
                opacity: 1;
            }
        }
    }

    &:disabled {
        background: ${AppColors.ellipseGrey};
        color: ${AppColors.white};
    }
`;

export const StyledCancelButton = styled(Button)`
    ${AppTypography.primaryButtonText};
    background: ${AppColors.white};
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    box-shadow: none;

    width: 302px;
    height: 60px;
    align-self: center;

    ${AppTypography.chargeeRegularText}
    font-size: 21px;
    color: ${AppColors.chargeeButton};
    border: #a9a9ad 1px solid;

    .MuiButton-label {
        z-index: 1;
    }

    &:hover {
        background: ${AppColors.hoverWhiteButton};
        &:not(:disabled) {
            &:before {
                opacity: 1;
            }
        }
    }
`;

export const StyledSecondaryButton = styled(Button)`
    ${AppTypography.regularText};
    background: ${AppColors.white};
    color: ${AppColors.ellipse};
    border-radius: 2px;
    padding: 0;
    min-width: 41px;

    &:disabled {
        background: ${AppColors.white};
    }
`;

export const StyledErrorButton = styled(Button)`
    ${AppTypography.primaryButtonText};
    color: ${AppColors.white};
    background-color: ${props => props.theme.palette.secondary.main};
    position: relative;
    overflow: hidden;
    box-shadow: none;
    border-radius: 10px;
    width: 302px;
    height: 46px;
    align-self: center;

    .MuiButton-label {
        z-index: 1;
    }

    span {
        width: fit-content;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }

    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${gradientLite};
        opacity: 0;
        transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        z-index: 0;
    }
    &:hover {
        background: ${gradientLite};

        &:not(:disabled) {
            &:before {
                opacity: 1;
            }
        }
    }

    &:disabled {
        background: ${AppColors.ellipseGrey};
        color: ${AppColors.white};
    }
`;

export const StyledTermsConditionsButton = styled(Button)`
    ${AppTypography.finOptionsBoldText};
    color: black;
    font-size: 18px;
    background-color: #1fd2b0;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    height: 60px;
    border-radius: 100px;
    align-self: center;

    .MuiButton-label {
        z-index: 1;
    }

    max-width: 317px;
    width: 140px;

    span {
        width: fit-content;
        display: inline-block;
    }

    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${gradientLite};
        opacity: 0;
        transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        z-index: 0;
    }

    &:hover {
        background: ${gradientTandCLite};
    }

    &:disabled {
        background: ${AppColors.ellipseGrey};
        color: ${AppColors.white};
    }
`;

export const StyledTermsCancelButton = styled(Button)`
    ${AppTypography.finOptionsBoldText};
    color: #0081e0;
    font-size: 18px;
    line-height: 1.2;
    background-color: white;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    height: 60px;
    border-radius: 100px;
    align-self: center;
    border: 1px solid #a6a6a6;

    .MuiButton-label {
        z-index: 1;
    }

    max-width: 317px;
    width: 140px;

    span {
        width: fit-content;
    }

    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${gradientLite};
        opacity: 0;
        transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        z-index: 0;
    }

    &:hover {
        background: ${gradientTandCCancelLite};
    }

    &:disabled {
        background: ${AppColors.ellipseGrey};
        color: ${AppColors.white};
    }
`;
