import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from 'src/store/root-reducer.models';

export const getAccountState = (state: ApplicationState) => state.accounts;

export const selectSelectedBankAccount = createSelector(
    getAccountState,
    ({ selectedAccount }) => selectedAccount
);

export const selectAggregatedAccounts = createSelector(
    getAccountState,
    ({ aggregatedAccounts }) => aggregatedAccounts
);

export const selectFiLoginAccounts = createSelector(
    getAccountState,
    ({ fiLoginAccounts }) => fiLoginAccounts
);
