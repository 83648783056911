import Lottie from 'react-lottie';

import animationData from 'src/assets/img/lottie/gears.json';

import { TEXT_VARS } from 'src/i18n/en';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { StyledText } from './general-error.styled';

type Props = { errMsg?: string };

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const GeneralError = ({ errMsg }: Props) => (
    <StyledPageWithPaddingContainer>
        <Lottie height={300} options={defaultOptions} width={300} />
        <StyledText>{errMsg || TEXT_VARS.COMMON_TEXT.OOPS_SMTH_WRONG}</StyledText>
    </StyledPageWithPaddingContainer>
);
