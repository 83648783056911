import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledRadioContainer = styled.div`
    padding-left: 0;

    .MuiFormControl-root {
        width: 100%;
    }
`;

export const StyledFormLabel = styled.div`
    margin-bottom: 10px;
    padding-bottom: 5px;
    ${AppTypography.chargeeRegularText};
    text-align: center;
    border-bottom: 2px solid #e7e8e9;
    font-weight: bold;
`;
