import React, { FunctionComponent, SVGProps } from 'react';
import {
    StyledBodyText,
    StyledInfoLayout,
    StyledLabelText,
    StyledWrapper,
} from './error-popup-redesign.styled';
import { AppButton } from 'src/components/button';
import { TEXT_VARS } from 'src/i18n/en';

interface IndexProps {
    Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    labelText: string;
    bodyText: string;
    isButtonDisabled?: boolean;
    onClick?: () => void;
}

export const ErrorPopupRedesign = ({ Icon, onClick, isButtonDisabled, labelText, bodyText }: IndexProps) => {
    return (
        <>
            <StyledWrapper>
                <Icon />
                <StyledLabelText>{labelText}</StyledLabelText>
                <StyledBodyText>{bodyText}</StyledBodyText>
                {onClick && (
                    <AppButton appButtonType={'ErrorReDesign'} onClick={onClick} disabled={isButtonDisabled}>
                        {TEXT_VARS.BUTTON.TRY_AGAIN}
                    </AppButton>
                )}
            </StyledWrapper>
            <StyledInfoLayout />
        </>
    );
};
