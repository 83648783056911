import { Theme } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useThunkAction } from 'src/hooks/useThunkAction.ts';

import { brandInfer } from 'src/store/config/actions.thunks.ts';
import { BrandDTO } from 'src/store/config/models.ts';
import { selectJWTToken } from 'src/store/user/selectors.ts';

export const useBranding = (theme: Theme) => {
    const token = useSelector(selectJWTToken)?.idToken;

    const [brandTheme, setBrandTheme] = useState(theme);

    const [brandInferAction] = useThunkAction(brandInfer);

    useEffect(() => {
        if (token) {
            const merchantId = (jwtDecode(token) as { MERCHANT_ID: string })?.MERCHANT_ID;

            if (merchantId) {
                brandInferAction(merchantId).then(r => {
                    if (!r.success) return;

                    setBrandTheme(prevTheme => ({ ...prevTheme, brandTheme: r.payload as BrandDTO }));
                });
            }
        }
    }, [brandInferAction, token]);

    return brandTheme;
};
