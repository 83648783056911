import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledSubTitle = styled.div`
    ${AppTypography.regularText};
    font-size: 20px;
    color: ${AppColors.primaryButtonTextColor};
    align-self: flex-start;
`;

export const StyledInfoText = styled.span`
    ${AppTypography.infoText};
    max-width: fit-content;
    margin: 15px 0;
`;

export const StyledBody = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 20px;
    margin-bottom: 30px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    .card-icon {
        align-self: unset;
        border: none;
        margin-left: -10px;
    }
`;

export const StyledCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${AppTypography.regularText};
    color: ${AppColors.screenTitle};
    line-height: 16px;

    .MuiInputBase-input {
        ${AppTypography.regularText};
        color: ${AppColors.screenTitle};
        line-height: 16px;
        padding: 6px 0 2px;
        text-align: right;
    }
`;

export const StyledCardRowText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
        align-items: center;
        display: inline-flex;
        padding-top: 6px;
    }

    .exp-cvv-text {
        width: 50px;
        margin-left: 10px;
    }

    .input {
        width: auto;
    }
`;

export const StyledTextField = styled(TextField)`
    width: 120px;
    .MuiInputBase-input {
    }
`;

export const StyledExpCVVSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledCardButtonSection = styled.div`
    display: grid;
    margin-top: 50px;
    padding: 0;
    grid-template-areas: 'c c c d';
    grid-template-columns: repeat(4, 1fr);

    .save-button {
        grid-area: c;
        justify-self: end;
        justify-content: flex-start;
    }
    .delete-button {
        grid-area: d;
        justify-self: end;
        color: ${AppColors.errorText};
    }
`;

export const StyledErrorTextBlock = styled.div`
    ${AppTypography.regularText};
    color: ${AppColors.errorText};
    opacity: 0.5;
    font-size: 10px;

    display: flex;
    margin-bottom: 12px;
    width: 100%;
`;

export const StyledSelectSection = styled.div`
    display: flex;
    flex-direction: row;
`;
