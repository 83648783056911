import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledAppInputComponent = styled.div`
    .app-input-title {
        margin-bottom: ${units.p8}px;
        ${AppTypography.appInputTitle};
    }
`;

export const AppInputTextField = styled(TextField)`
    width: 301px;
`;
