import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.DEV,
    firebaseConfig: {
        apiKey: 'AIzaSyDNf6olSwxeqIQzIzIiTtuIzsxHX7hkFhU',
        authDomain: 'billmybank.firebaseapp.com',
        databaseURL: 'https://billmybank.firebaseio.com',
        projectId: 'billmybank',
        storageBucket: 'billmybank.appspot.com',
        messagingSenderId: '750709542523',
        appId: '1:750709542523:web:1f0121262a1b57fb3a9cde',
        measurementId: 'G-7K5PGPL4TD',
    },
    siteId: 'bmb-sdk',
    baseApiUrl: 'https://api-dev.billmybank.com',
    fiServeWidgetBaseUrl: 'https://ais-ui-uat.fiservapps.com',
    showBnpl: true,
    iOSStore: 'https://apps.apple.com/us/app/billmybank/id6446296281',
    googleStore: 'https://play.google.com/store/apps/details?id=com.billmybank.app.dev',
    branchKey: 'key_test_nuiQW3x2VTZD14Dph4qw5hikzqjti2T1',
};

export default config;
