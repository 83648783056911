import { Card } from 'src/store/cards/models';

export const isCreditCardExpired = (creditCard: Card) => {
    const currentDate = new Date();

    // expire year is received in format: @number XX
    // expire month is received in format: @number XX (from 1 to 12)
    const expireCardDate = new Date(creditCard.expYear + 2000, creditCard.expMonth - 1);
    return currentDate > expireCardDate;
};
