import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledCardComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 23px;

    .credit-card-svg {
        min-width: 60px;
        height: 54px;
        margin-right: 30px;
    }
`;

export const StyledHelpIconTitle = styled.div`
    white-space: pre-line;
    ${AppTypography.regularText};
    color: ${AppColors.helpIconText};
    display: flex;
    flex-direction: column;

    .h1 {
        font-weight: bold;
    }

    .h2 {
        line-height: 1.5;
    }
`;

export const StyledCardData = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .error-message {
        color: ${AppColors.errorText};
        opacity: 0.5;
        font-size: 10px;
    }

    .error {
        color: ${AppColors.errorText};
    }
`;

export const StyledTextBlock = styled.div`
    ${AppTypography.regularText};
    color: ${AppColors.screenTitle};

    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
`;

export const StyledRowBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledBorder = styled.div`
    margin: 10px 0;
    height: 0;
    border: solid 0.6px #707070;
`;

export const StyledLinkButton = styled.a`
    text-decoration: none;
    align-self: center;
    color: ${AppColors.ellipse};
    font-size: 10px;
    cursor: pointer;
`;

export const StyledSubtitle = styled.span`
    ${AppTypography.regularText};
    color: ${AppColors.primaryButtonTextColor};
    font-weight: 600;
`;

export const StyledSubtitleSection = styled.div`
    display: flex;
    flex-direction: row;

    .error {
        color: ${AppColors.errorText};
    }
`;
