import React, { useCallback, useMemo } from 'react';
import { AppRadioButton } from 'src/components/radiobutton';
import {
    StyledCardInfo,
    StyledCardTypeNumberSection,
    StyledCardActionSection,
    StyledCreditCard,
    StyledCardButtonSection,
} from 'src/components/credit-card/credit-card.styled';
import { TEXT_VARS } from 'src/i18n/en';
import { CreditCardIcon } from 'src/screens/confirmation/components/credit-card-icon';
import { Card } from 'src/store/cards/models';
import { AppButton } from 'src/components/button';

export const CreditCardComponent: React.FC<{
    creditCard: Card;
    onClick(card: Card): void;
    handleDeleteButtonClick(cardId: string): void;
    isRadioButtonChecked: boolean | null;
}> = ({ creditCard, onClick, isRadioButtonChecked, handleDeleteButtonClick }) => {
    const { expMonth, expYear, redactedNumber } = useMemo(() => creditCard, [creditCard]);
    const handleClick = useCallback(() => onClick(creditCard), [creditCard, onClick]);

    const transformedCardNumber = useMemo(() => {
        return `*******${redactedNumber.slice(redactedNumber.length - 4)}`;
    }, [redactedNumber]);

    const _handleDeleteButtonClick = useCallback(() => {
        handleDeleteButtonClick(creditCard.id);
    }, [creditCard.id, handleDeleteButtonClick]);

    return (
        <>
            <StyledCreditCard>
                {isRadioButtonChecked != null && (
                    <AppRadioButton onClick={handleClick} checked={isRadioButtonChecked} />
                )}
                <StyledCardInfo>
                    <StyledCardTypeNumberSection>
                        <CreditCardIcon cardType={creditCard.cardType} className={'svg-icon'} />
                        <span>{transformedCardNumber}</span>
                    </StyledCardTypeNumberSection>
                    <StyledCardActionSection>
                        <span className={'exp-date'}>{`${expMonth}/${expYear}`}</span>
                        <StyledCardButtonSection>
                            <AppButton
                                onClick={_handleDeleteButtonClick}
                                appButtonType={'Secondary'}
                                className={'delete-button'}
                            >
                                {TEXT_VARS.BUTTON.DELETE}
                            </AppButton>
                        </StyledCardButtonSection>
                    </StyledCardActionSection>
                </StyledCardInfo>
            </StyledCreditCard>
        </>
    );
};
