import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AppInputTextField, StyledAppInputComponent } from 'src/components/app-input/app-input.styled';
import { TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import { StyledExtendable } from 'src/utils/sharedModels';

interface AppInputComponentProps {
    appInputTitle?: string;
    className?: string;
    inputValue: string;
    label?: string;
    handleInputChange(value: string): void;
    inputErrorText?: string;
    inputValidationRegexp?: RegExp;
    firstRender?: boolean;
    setIsInputError?: (value: boolean) => void;
    isChargee?: boolean;
    setIsValidationError?: React.Dispatch<React.SetStateAction<boolean>>;
}

type ValidatorResult = 'valid' | 'invalid' | undefined;

export const AppInput: React.FC<TextFieldProps & AppInputComponentProps & StyledExtendable> = ({
    className,
    appInputTitle,
    inputValue,
    label,
    handleInputChange,
    inputErrorText,
    inputValidationRegexp,
    firstRender,
    setIsInputError,
    isChargee,
    setIsValidationError,
    ...props
}) => {
    const [isFocused, setFocused] = useState(false);

    const handleInputValueChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(e.target.value);
        },
        [handleInputChange]
    );

    const handleFocus = useCallback(() => setFocused(true), [setFocused]);
    const handleBlur = useCallback(() => setFocused(false), [setFocused]);

    const validate = useCallback(
        (_inputValue: any) => {
            if (!inputValidationRegexp) {
                return 'invalid';
            } else return inputValidationRegexp.test(_inputValue) ? 'valid' : 'invalid';
        },
        [inputValidationRegexp]
    );

    const validationState: ValidatorResult = useMemo(() => {
        const validationResult = validate(inputValue);
        const validationResultIfNoFocus = isFocused ? undefined : validationResult;
        return validationResult === 'valid' ? validationResult : validationResultIfNoFocus;
    }, [isFocused, inputValue, validate]);

    const isError = useMemo(() => {
        if (firstRender) {
            return false;
        } else {
            return validationState === 'invalid';
        }
    }, [firstRender, validationState]);

    const helperText = useMemo(() => {
        return isError ? inputErrorText : undefined;
    }, [inputErrorText, isError]);

    useEffect(() => {
        if (setIsInputError && validationState === 'invalid') {
            setIsInputError(true);
        } else setIsInputError && setIsInputError(false);
    }, [isError, setIsInputError, validationState]);

    useEffect(() => {
        setIsValidationError && setIsValidationError(validationState !== 'valid');
    }, [setIsValidationError, validationState]);

    return (
        <StyledAppInputComponent>
            {appInputTitle && (
                <div className={classNames('app-input-title')}>{appInputTitle.toUpperCase()}</div>
            )}
            <AppInputTextField
                className={classNames(className)}
                value={inputValue}
                label={isChargee ? '' : label}
                onChange={handleInputValueChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                error={isError}
                helperText={helperText}
                variant="standard"
                {...props}
            />
        </StyledAppInputComponent>
    );
};
