import React, { useCallback, useMemo } from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import animationData from 'src/assets/img/lottie/consent-img.json';

import { AppButton } from 'src/components/button';
import { Copyright } from 'src/components/copyright';
import { Loader } from 'src/components/loader';
import { Title } from 'src/components/title';

import { useAppRoute } from 'src/hooks/useAppRoute';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import { selectPrepayment } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { StyledTitle, StyledConfirmButton } from './consent-link.styled';

export const ConsentLink = () => {
    const route = useAppRoute();
    const prepayment = useSelector(selectPrepayment);
    const merchantName = prepayment?.merchantName;

    const handleContinueButtonClick = useCallback(() => {
        route(AppRoutes.customerSignIn);
    }, [route]);

    const defaultOptions = useMemo(
        () => ({
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        }),
        []
    );

    return (
        <StyledPageWithPaddingContainer>
            <Loader isShowing={false} />
            <StyledTitle>
                <Title title={`${TEXT_VARS.TITLE.START_SETTING_UP} ${merchantName}`} />
            </StyledTitle>
            <Lottie height={300} options={defaultOptions} width={300} />

            <StyledConfirmButton>
                <AppButton appButtonType="Continue" onClick={handleContinueButtonClick}>
                    {TEXT_VARS.BUTTON.CONFIRM}
                </AppButton>
            </StyledConfirmButton>
            <Copyright />
        </StyledPageWithPaddingContainer>
    );
};
