import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledChargesComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 23px;

    .hidden-div {
        min-width: 60px;
        margin-right: 30px;
    }
`;

export const StyledTextBlock = styled.div`
    ${AppTypography.chargeeRegularText};
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
`;

export const StyledTotalTextBlock = styled.div<{ fontSize?: string }>`
    ${AppTypography.chargeeRegularThinText};
    font-size: ${props => props.fontSize || ''};
    display: flex;
    flex-direction: column;
    width: 100%;

    .total-charges {
        font-weight: 600;
    }
`;

export const StyledSubtitle = styled.span<{ $isChargee: boolean }>`
    ${AppTypography.chargeeRegularText};
    font-weight: 600;
    font-size: 14px;
`;

export const StyledBorder = styled.div`
    margin: 10px 0;
    height: 0;
    border: solid 0.6px #707070;
`;
