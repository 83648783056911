import styled from '@emotion/styled';
import { useCallback } from 'react';

import { InfoActionLayout } from 'src/components/info-action-layout';

import { useAppRoute } from 'src/hooks/useAppRoute';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

const StyledTitle = styled.div`
    text-align: center;
`;

const StyledBodyError = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px;

    span {
        margin: 5px;
    }
`;

export const ErrorPageFiserv = () => {
    const route = useAppRoute();

    const handleInfoLayoutActionClick = useCallback(() => {
        route(AppRoutes.customerSelectYourBankAccount);
    }, [route]);

    return (
        <>
            <StyledTitle>Error Page</StyledTitle>
            <StyledBodyError>
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            </StyledBodyError>
        </>
    );
};
