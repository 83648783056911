import { AccountState, BankAccount, BankAccountInfoDTO } from 'src/store/accounts/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const INITIAL_STATE: AccountState = {};

const accountReducer = createSlice({
    name: 'AccountsState',
    initialState: INITIAL_STATE,
    reducers: {
        clearAccountState: () => INITIAL_STATE,
        saveSelectedBankAccount: (state, action: PayloadAction<BankAccount | undefined>) => {
            state.selectedAccount = action.payload;
        },
        clearSelectedAccount: state => {
            state.selectedAccount = undefined;
        },
        clearAggregatedAccounts: state => {
            state.aggregatedAccounts = undefined;
        },
        saveAggregatedBankAccounts: (state, action: PayloadAction<BankAccount[]>) => {
            state.aggregatedAccounts = action.payload;
        },
        saveFiLoginAccounts: (state, action: PayloadAction<BankAccountInfoDTO[]>) => {
            state.fiLoginAccounts = action.payload;
        },
    },
});

export const {
    clearAccountState,
    clearAggregatedAccounts,
    clearSelectedAccount,
    saveAggregatedBankAccounts,
    saveSelectedBankAccount,
    saveFiLoginAccounts,
} = accountReducer.actions;
export default accountReducer.reducer;

// export const accountReducer2 = createReducer<typeof INITIAL_STATE, AccountActions>(INITIAL_STATE)
// .handleAction(
//     clearAccountState,
//     (): AccountState => ({
//         ...INITIAL_STATE,
//     })
// )
//
// .handleAction(
//     saveSelectedBankAccount,
//     (state, action): AccountState => ({
//         ...state,
//         selectedAccount: action.payload,
//     })
// )
//
// .handleAction(
//     clearSelectedAccount,
//     (state): AccountState => ({
//         ...state,
//         selectedAccount: undefined,
//     })
// )
//
// .handleAction(
//     clearAggregatedAccounts,
//     (state): AccountState => ({
//         ...state,
//         aggregatedAccounts: undefined,
//     })
// )
//
// .handleAction(
//     saveAggregatedBankAccounts,
//     (state, action): AccountState => ({
//         ...state,
//         aggregatedAccounts: action.payload,
//     })
// )
//
// .handleAction(
//     saveFiLoginAccounts,
//     (state, action): AccountState => ({
//         ...state,
//         fiLoginAccounts: action.payload,
//     })
// );
