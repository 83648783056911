import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history } from 'src/store';

import { AppRootContainer } from 'src/components/app-root-container';

import { WrappedAppRouter } from 'src/router/components/wrappedAppRouter';

import 'src/fonts.css';

export const CustomerRouter = () => {
    return (
        <Router history={history}>
            <AppRootContainer>
                <WrappedAppRouter />
            </AppRootContainer>
        </Router>
    );
};

export default CustomerRouter;
