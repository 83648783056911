import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.SANDBOX,
    firebaseConfig: {
        apiKey: 'AIzaSyA6u8iW_13MUY236KkHwy7_C05IqMCFSbQ',
        authDomain: 'billmybank-sandbox.firebaseapp.com',
        databaseURL: 'https://billmybank-sandbox.firebaseio.com',
        projectId: 'billmybank-sandbox',
        storageBucket: 'billmybank-sandbox.appspot.com',
        messagingSenderId: '836880606695',
        appId: '1:836880606695:web:aac4b8db9a73827f9a8942',
        measurementId: 'G-7PCN8G2TC5',
    },
    siteId: 'bmb-sdk-sandbox',
    baseApiUrl: 'https://api-sandbox.billmybank.com',
    fiServeWidgetBaseUrl: 'https://ais-ui.fiservapps.com',
    showBnpl: false,
    sentryConfig: {
        dsn: 'https://c6da10034243471c8169bbb62c329db6@o503848.ingest.sentry.io/5589529',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.1,
    },
    iOSStore: 'https://apps.apple.com/us/app/billmybank/id6446295989',
    googleStore: 'https://play.google.com/store/apps/details?id=com.billmybank.app.stage',
    branchKey: 'key_test_nuiQW3x2VTZD14Dph4qw5hikzqjti2T1',
};

export default config;
