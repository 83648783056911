import React, { useMemo } from 'react';
import classNames from 'classnames';
import VisaSVG from 'src/assets/img/visa.svg?react';
import MasterCard from 'src/assets/img/mastercard.svg?react';
import AMEX from 'src/assets/img/amex.svg?react';
import DinersClub from 'src/assets/img/diners-club.svg?react';
import Discover from 'src/assets/img/discover.svg?react';
import JBC from 'src/assets/img/jcb.svg?react';
import PlaceholderIcon from 'src/assets/img/credit-card-placeholder-icon.svg?react';
import { StyledCreditCardIcon } from 'src/components/add-credit-card/components/credit-card-icon.styled';

interface CreditCardIconProps {
    cardNumberObject: any;
    className?: string;
}

export const CreditCardIcon: React.FC<CreditCardIconProps> = ({ cardNumberObject, className }) => {
    const isVisa = cardNumberObject.card && cardNumberObject.card.type === 'visa';
    const isMasterCard = cardNumberObject.card && cardNumberObject.card.type === 'mastercard';
    const isAmericanExpress = cardNumberObject.card && cardNumberObject.card.type === 'american-express';
    const isDinersClub = cardNumberObject.card && cardNumberObject.card.type === 'diners-club';
    const isDiscover = cardNumberObject.card && cardNumberObject.card.type === 'discover';
    const isJCB = cardNumberObject.card && cardNumberObject.card.type === 'jcb';

    const unknownCard = useMemo(() => {
        return (
            !isVisa && !isMasterCard && !isAmericanExpress && !isDinersClub && !isDiscover && !isJCB && true
        );
    }, [isVisa, isMasterCard, isAmericanExpress, isDinersClub, isDiscover, isJCB]);

    return (
        <StyledCreditCardIcon className={classNames(className)}>
            {isVisa && <VisaSVG className={'credit-card-svg'} />}
            {isMasterCard && <MasterCard className={'credit-card-svg'} />}
            {isAmericanExpress && <AMEX className={'credit-card-svg'} />}
            {isDinersClub && <DinersClub className={'credit-card-svg'} />}
            {isDiscover && <Discover className={'credit-card-svg'} />}
            {isJCB && <JBC className={'credit-card-svg'} />}
            {unknownCard && <PlaceholderIcon className={'credit-card-svg'} />}
        </StyledCreditCardIcon>
    );
};
