import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from 'src/store/root-reducer.models';

export const getPaymentState = (state: ApplicationState) => state.payment;
export const selectPreparePaymentResponse = createSelector(
    getPaymentState,
    ({ preparePayment }) => preparePayment
);

export const selectCardAuthMode = createSelector(
    getPaymentState,
    ({ preparePayment }) => preparePayment && preparePayment.cardAuthMode
);

export const selectFinancingOption = createSelector(
    getPaymentState,
    ({ selectedFinancingOption }) => selectedFinancingOption
);
