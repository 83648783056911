import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledLoader = styled.div<{ $isShowing: boolean }>`
    z-index: 99999;
    pointer-events: ${({ $isShowing }) => ($isShowing ? 'auto' : 'none')};
    opacity: ${({ $isShowing }) => ($isShowing ? 1 : 0)};
    transition: opacity 200ms ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before {
        position: absolute;
        content: '';
        background: ${AppColors.white};
        width: 100%;
        height: 100%;
    }

    &.relative {
        position: relative;
    }

    &.without-background {
        &:before {
            display: none;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }

    .spinner {
        margin-top: -200px;
        animation: spin 2s linear infinite;
    }
`;

export const StyledMarketingWords = styled.div`
    z-index: 1;
    margin-top: 56px;
    ${AppTypography.primaryButtonText};
    line-height: 32px;
    display: flex;
    flex-direction: column;

    .top-text {
        color: ${AppColors.white};
        letter-spacing: -0.56px;
    }
    .bottom-text {
        text-align: end;
        color: ${AppColors.marketingText};
        font-size: 24px;
        letter-spacing: -0.75px;
    }
`;
