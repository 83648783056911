import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BRANDS } from 'src/configuration/constants';

import { ApplicationModes, ConfigState } from 'src/store/config/models';

export const INITIAL_STATE: ConfigState = { brand: BRANDS.BILLMYBANK, appMode: 'webApp' };

const configReducer = createSlice({
    name: 'ConfigState',
    initialState: INITIAL_STATE,
    reducers: {
        saveBrandName: (state, action: PayloadAction<BRANDS>) => {
            state.brand = action.payload;
        },
        setAppMode: (state, action: PayloadAction<ApplicationModes>) => {
            state.appMode = action.payload;
        },
    },
});

export const { saveBrandName, setAppMode } = configReducer.actions;
export default configReducer.reducer;
