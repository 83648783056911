import { useTheme } from '@mui/material/styles';
import React from 'react';

import { BrandLogo } from 'src/components/header/components/brand-logo.tsx';

import { CenterBlock, StyledHeader, StyledToolbar } from './header.styled';

const Header = () => {
    const theme = useTheme();

    return (
        <StyledHeader>
            <StyledToolbar>
                <CenterBlock>
                    <BrandLogo logoUrl={theme.brandTheme?.brandProperties?.logoUrl} />
                </CenterBlock>
            </StyledToolbar>
        </StyledHeader>
    );
};

export default Header;
