import React from 'react';

import { Img } from 'src/components/img';

import { getApplicationConfig } from 'src/configuration/config.ts';

import { ApplicationConfig } from 'src/types/applicationConfig.type.ts';

interface BrandLogoProps {
    logoUrl: string | undefined;
}

const { brandName, images }: ApplicationConfig = getApplicationConfig();

const imgStyles = { maxHeight: '60px' };

export const BrandLogo = ({ logoUrl }: BrandLogoProps) => {
    return <Img alt={brandName} src={logoUrl || images?.appLogoText} style={imgStyles} />;
};
