import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';
import { RadioGroup } from '@mui/material';

import { getApplicationConfig } from 'src/configuration/config';

const { brand } = getApplicationConfig();

const backgroundColor = brand === 'BMB' ? 'rgba(31, 124, 210, 0.4)' : 'rgba(31, 210, 176, 0.4)';

export const StyledRadioGroup = styled(RadioGroup)`
    && {
        height: fit-content;
        overflow: auto;
        display: flex;
        flex-direction: row;
    }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
    width: 95%;
    .MuiFormControlLabel-label {
        width: 100%;
    }
    align-self: start;
`;

export const StyledCustomLabel = styled('div', {
    shouldForwardProp: prop => prop !== '$isChecked' && prop !== '$isDirectPayment',
})<{ $isChecked: boolean; $isDirectPayment: boolean }>`
    border: 1px solid #e7e8e9;
    border-radius: ${props => (props.$isChecked ? '10px 10px 0 0' : '10px')};
    border-radius: ${props => (props.$isDirectPayment ? '10px' : '')};
    width: 100%;
    padding: 11px 0 11px 14px;
    font-weight: bold;
    background: ${props => (props.$isChecked ? backgroundColor : '')};
`;

export const StyledInstallments = styled.div`
    border: 1px solid #e7e8e9;
    background: ${backgroundColor};
    padding: 11px 0 11px 14px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
`;

export const StyledDataText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span:first-child {
        font-weight: bold;
    }

    span:nth-child(even) {
        margin-right: 11px;
    }

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;
