import {
    InstitutionEndpoints,
    MerchantEndpoints,
    PaymentEndpoints,
    UserEndpoints,
    PrePaymentResource,
    SecurityResource,
    AgreementResource,
    PaymentLinkResource,
    ChangeResource,
    CustomerResource,
    DefaultResource,
    BrandResource,
} from 'src/configuration/endpoints.config';

const publicEndpoints = {};

const privateEndpoints = {
    ...UserEndpoints,
    ...InstitutionEndpoints,
    ...PaymentEndpoints,
    ...MerchantEndpoints,
    ...PrePaymentResource,
    ...SecurityResource,
    ...AgreementResource,
    ...PaymentLinkResource,
    ...ChangeResource,
    ...CustomerResource,
    ...DefaultResource,
    ...BrandResource,
};

export const ENDPOINT = {
    ...privateEndpoints,
    ...publicEndpoints,
};
