import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

const hide = keyframes`
        0%, 99% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            display: none;
        }
        
    `;

export const StyledCheckMarkPopup = styled.div`
    z-index: 1000;
    pointer-events: all;
    position: absolute;
    left: 0;
    right: 0;
    width: 360px;
    height: 365px;
    top: 60px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: ${hide} 3s forwards;
`;

export const StyledText = styled.span`
    ${AppTypography.chargeeRegularThinText};
    font-size: 21px;
`;
