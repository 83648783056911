import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledCopyright = styled.div`
    margin-top: 20px;
    ${AppTypography.chargeeCopyrightText};
    color: ${AppColors.screenTitle};

    display: flex;
    justify-content: center;
    width: auto;
`;
