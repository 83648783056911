import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import CircleCheckSVG from 'src/assets/img/Font_Awesome_5_solid_check-circle.svg?react';
import ExclamationMarkSVG from 'src/assets/img/exclamation-mark.svg?react';

import { AppButton } from 'src/components/button';
import {
    StyledBody,
    StyledBodySection,
    StyledBodyText,
    StyledInfoLayout,
    StyledSVG,
    StyledButtonWrapper,
    StyledLinkButton,
} from 'src/components/info-action-layout/info-action-layout.styled';

import { TEXT_VARS } from 'src/i18n/en';

import { selectResponseData } from 'src/store/user/selectors';

export type InfoActionLayoutType = 'success' | 'error';

interface SuccessLayoutProps {
    type: InfoActionLayoutType;
    onClick(): void;
    setIsCancelPopupDisplayed?: React.Dispatch<React.SetStateAction<boolean>>;
    buttonTitle?: string;
    linkButtonTitle?: string;
    titleText: string;
    bodyText?: string;
    isActionComplete?: boolean;
}

export const InfoActionLayout: React.FC<SuccessLayoutProps> = ({
    type,
    onClick,
    setIsCancelPopupDisplayed,
    titleText,
    bodyText,
    buttonTitle,
    linkButtonTitle,
    isActionComplete,
}) => {
    const response = useSelector(selectResponseData);

    const handleContinueClick = useCallback(() => {
        onClick();
    }, [onClick]);

    const handleLinkButtonClick = useCallback(() => {
        setIsCancelPopupDisplayed && setIsCancelPopupDisplayed(false);
    }, [setIsCancelPopupDisplayed]);

    const Icon: React.FC = () => {
        return type === 'success' ? <CircleCheckSVG /> : <ExclamationMarkSVG />;
    };

    const _bodyText = useMemo(() => {
        const _statusNumberText = response?.status ? `Status: ${response?.status}.` : '';
        const _statusText = response?.statusText ? `Status Text: ${response?.statusText}.` : '';
        const _detailsText = response?.data.detail ? `Details: ${response?.data.detail}.` : '';

        return bodyText ? bodyText : `${_statusNumberText} \n${_statusText} \n${_detailsText}`;
    }, [bodyText, response]);

    const buttonType = useMemo(() => {
        return type === 'success' ? 'Continue' : 'Error';
    }, [type]);

    return (
        <>
            <StyledBodySection>
                <StyledSVG>
                    <Icon />
                </StyledSVG>
                <StyledBody>
                    <StyledBodyText>
                        <p className="text-subtitle">{titleText}</p>
                        <div className="text-body">{_bodyText}</div>
                    </StyledBodyText>
                    {!isActionComplete && (
                        <StyledButtonWrapper>
                            <AppButton appButtonType={buttonType} onClick={handleContinueClick}>
                                {buttonTitle || TEXT_VARS.BUTTON.OK}
                            </AppButton>
                            {linkButtonTitle && (
                                <StyledLinkButton onClick={handleLinkButtonClick}>
                                    {linkButtonTitle}
                                </StyledLinkButton>
                            )}
                        </StyledButtonWrapper>
                    )}
                </StyledBody>
            </StyledBodySection>
            <StyledInfoLayout type={type} />
        </>
    );
};
