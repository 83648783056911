import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSignInState } from 'src/store/user/selectors';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from 'src/router/user-router/private';
import PublicRoutes from 'src/router/user-router/public';

const AppRouter: React.FC = () => {
    const isUserAuthenticated = useSelector(selectSignInState);

    return useMemo(
        () => (
            <Routes>
                <Route path="*" element={isUserAuthenticated ? <PrivateRoutes /> : <PublicRoutes />} />
            </Routes>
        ),
        [isUserAuthenticated]
    );
};

export default AppRouter;
