import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledTitle = styled.div`
    margin-top: 35px;
    align-self: center;
    ${AppTypography.chargeeRegularText};
    font-size: 21px;
`;

export const StyledTextBlock = styled.div`
    display: flex;
    margin-bottom: 12px;
    width: 100%;
`;

export const StyledLabel = styled.span`
    ${AppTypography.finOptionsRegularText};
    color: #00000080;
    opacity: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-right: 5px;
`;

export const StyledLabelText = styled.span`
    ${AppTypography.finOptionsRegularText};
    opacity: 100%;
    font-size: 18px;
    font-weight: bold;
`;

export const StyledPaymentCodeSection = styled.div`
    margin-top: 40px;
    margin-bottom: 20px;
    ${AppTypography.chargeeRegularText};
    border-bottom: 1px solid #b7b7b7;
    line-height: 2;
`;

export const StyledButtonSection = styled.div`
    margin-top: 100px;
`;

export const StyledConfirmButton = styled.div`
    align-self: center;
`;

export const StyledCancelButton = styled.div`
    align-self: center;
    margin: 12px 0 0;
`;
