import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BankAccount } from 'src/store/accounts/models';
import {
    CustomerDTO,
    FiServeToken,
    Merchant,
    PaymentLinkDTO,
    PrePaymentDTO,
    QueryParams,
    User,
    UserAgreementDTO,
    UserState,
} from 'src/store/user/models';

export const INITIAL_STATE: UserState = {
    authenticated: false,
    user: null,
    customer: null,
    queryParams: null,
    generatedJWTToken: null,
};

const userReducer = createSlice({
    name: 'UserState',
    initialState: INITIAL_STATE,
    reducers: {
        signInAction: (state, action: PayloadAction<User>) => {
            state.authenticated = true;
            state.user = action.payload;
        },
        signOutAction: () => INITIAL_STATE,
        createCustomerThunk: (state, action: PayloadAction<CustomerDTO>) => {
            state.customer = action.payload;
        },
        storeQueryParams: (state, action: PayloadAction<QueryParams>) => {
            state.queryParams = action.payload;
        },
        saveCurrentCustomer: (state, action: PayloadAction<CustomerDTO | null>) => {
            state.customer = action.payload;
        },
        saveConnectWidgetData: (state, action: PayloadAction<any>) => {
            state.connectWidgetData = action.payload;
        },
        saveMerchant: (state, action: PayloadAction<Merchant>) => {
            state.merchant = action.payload;
        },
        savePaymentLink: (state, action: PayloadAction<PaymentLinkDTO>) => {
            state.paymentLink = action.payload;
        },
        saveResponseError: (state, action: PayloadAction<any>) => {
            state.response = action.payload;
        },
        savePrepayment: (state, action: PayloadAction<PrePaymentDTO>) => {
            state.prepayment = action.payload;
        },
        addBankAccount: (state, action: PayloadAction<BankAccount>) => {
            if (!state.customer) {
                return;
            }
            state.customer.accounts = [...state.customer.accounts, action.payload];
        },
        saveIsCustomerNew: (state, action: PayloadAction<boolean>) => {
            state.isCustomerNew = action.payload;
        },
        saveGeneratedJWTToken: (
            state,
            action: PayloadAction<{
                expiresIn: number;
                idToken: string;
                refreshToken: string;
            } | null>
        ) => {
            state.generatedJWTToken = action.payload;
        },
        saveMerchantIdToken: (state, action: PayloadAction<string>) => {
            if (!state.user) {
                return;
            }
            state.user.idToken = action.payload;
        },
        saveAgreements: (state, action: PayloadAction<UserAgreementDTO>) => {
            if (!state.agreements) {
                state.agreements = {};
            }
            state.agreements.agreement = action.payload;
        },
        saveAgreementAcceptation: (state, action: PayloadAction<boolean>) => {
            if (!state.agreements) {
                state.agreements = {};
            }
            state.agreements.accepted = action.payload;
        },
        saveSelectFiservToken: (state, action: PayloadAction<FiServeToken>) => {
            state.fiservSessionObj = action.payload;
        },
        saveFinancingOptions: (state, action: PayloadAction<string>) => {
            state.financingOptions = action.payload;
        },
    },
});

export const {
    signInAction,
    signOutAction,
    createCustomerThunk,
    storeQueryParams,
    saveCurrentCustomer,
    saveConnectWidgetData,
    saveMerchant,
    savePaymentLink,
    saveResponseError,
    savePrepayment,
    addBankAccount,
    saveIsCustomerNew,
    saveGeneratedJWTToken,
    saveMerchantIdToken,
    saveAgreements,
    saveAgreementAcceptation,
    saveSelectFiservToken,
    saveFinancingOptions,
} = userReducer.actions;
export default userReducer.reducer;

// export const userReducer2 = createReducer<typeof INITIAL_STATE, UserActions>(INITIAL_STATE);
// .handleAction(
//     signInAction,
//     (state, action): UserState => ({
//         ...state,
//         authenticated: true,
//         user: action.payload,
//     })
// )
// .handleAction(
//     signOutAction,
//     (state): UserState => ({
//         authenticated: false,
//         user: null,
//         customer: null,
//         queryParams: state.queryParams,
//         generatedJWTToken: null,
//     })
// )
//
// .handleAction(
//     createCustomerThunk,
//     (state, action): UserState => ({
//         ...state,
//         customer: action.payload,
//     })
// )
//
// .handleAction(
//     storeQueryParams,
//     (state, action): UserState => ({
//         ...state,
//         queryParams: action.payload,
//     })
// )
//
// .handleAction(
//     saveCurrentCustomer,
//     (state, action): UserState => ({
//         ...state,
//         customer: action.payload,
//     })
// )
//
// .handleAction(
//     saveConnectWidgetData,
//     (state, action): UserState => ({
//         ...state,
//         connectWidgetData: action.payload,
//     })
// )
//
// .handleAction(
//     saveMerchant,
//     (state, action): UserState => ({
//         ...state,
//         merchant: action.payload,
//     })
// )
//
// .handleAction(
//     savePaymentLink,
//     (state, action): UserState => ({
//         ...state,
//         paymentLink: action.payload,
//     })
// )
// .handleAction(
//     saveResponseError,
//     (state, action): UserState => ({
//         ...state,
//         response: action.payload,
//     })
// )
// .handleAction(
//     savePrepayment,
//     (state, action): UserState => ({
//         ...state,
//         prepayment: action.payload,
//     })
// )
//
// .handleAction(
//     addBankAccount,
//     (state, action): UserState => ({
//         ...state,
//         customer: {
//             ...state.customer!,
//             accounts: [action.payload, ...state.customer!.accounts],
//         },
//     })
// )
//
// .handleAction(
//     saveIsCustomerNew,
//     (state, action): UserState => ({
//         ...state,
//         isCustomerNew: action.payload,
//     })
// )
//
// .handleAction(
//     saveGeneratedJWTToken,
//     (state, action): UserState => ({
//         ...state,
//         generatedJWTToken: action.payload,
//     })
// )
//
// .handleAction(saveMerchantIdToken, (state, action): UserState => {
//     return {
//         ...state,
//         ...(state.user && { user: { ...state.user, idToken: action.payload } }),
//     };
// })
//
// .handleAction(saveAgreements, (state, action): UserState => {
//     return {
//         ...state,
//         agreements: {
//             ...state.agreements,
//             agreement: action.payload,
//         },
//     };
// })
// .handleAction(saveAgreementAcceptation, (state, action): UserState => {
//     return {
//         ...state,
//         agreements: {
//             ...state.agreements,
//             accepted: action.payload,
//         },
//     };
// })
//
// .handleAction(saveSelectFiservToken, (state, action): UserState => {
//     return {
//         ...state,
//         fiservSessionObj: action.payload,
//     };
// });
