import { BRANDS } from 'src/configuration/constants';

import { ConfigCommon } from 'src/types/config.interface';

const config: ConfigCommon = {
    brand: BRANDS.CHARGEE,
    brandName: 'Chargee',
    appTld: 'chargee.co',
    termsUrl: 'https://chargee.co/terms/',
    privacyUrl: 'https://charge.co/privacy/',
    theme: {
        palette: {
            primary: {
                main: '#009789',
                light: '#00E0CC',
            },
            secondary: {
                main: '#f27405',
                light: '#d2a535',
            },
            error: {
                main: '#f20505',
                light: '#00a609',
            },
        },
    },
};

export default config;
