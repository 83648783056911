import { BRANDS } from 'src/configuration/constants';

import { ConfigCommon } from 'src/types/config.interface';

const config: ConfigCommon = {
    brand: BRANDS.BILLMYBANK,
    brandName: 'BillMyBank',
    appTld: 'billmybank.com',
    termsUrl: 'https://billmybank.com/terms-of-use/',
    privacyUrl: 'https://billmybank.com/privacy-policy/',

    theme: {
        palette: {
            primary: {
                main: '#0081E0',
                light: '#00E0CC',
            },
            secondary: {
                main: '#f27405',
                light: '#d2a535',
            },
            error: {
                main: '#f20505',
                light: '#00a609',
            },
        },
    },
};

export default config;
