import styled from '@emotion/styled';

import { TOP_BAR_HEIGHT } from 'src/theme/global-styles';

export const StyledAppRootContainer = styled.div`
    // padding-top: ${TOP_BAR_HEIGHT}px;
    // padding-top: calc(env(safe-area-inset-top) + ${TOP_BAR_HEIGHT}px);
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const StyledDashboardRootContainer = styled.div`
    padding-top: ${TOP_BAR_HEIGHT}px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;
