import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledText = styled.span`
    ${AppTypography.chargeeRegularThinText};
    font-size: 21px;
    white-space: break-spaces;
    text-align: center;
    line-height: 1.3;
`;
