import { Radio as MaterialRadio } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledAppRadioButtonProps {
    $buttonColor?: string;
}

export const StyledAppRadioButton = styled(MaterialRadio, {
    shouldForwardProp: prop => prop !== '$buttonColor',
})<StyledAppRadioButtonProps>`
    &.MuiRadio-colorPrimary,
    &.MuiRadio-colorSecondary {
        --fill-color: ${props => props.$buttonColor || props.theme.palette.primary.main};

        &.Mui-disabled {
            --fill-color: rgba(0, 0, 0, 0.26);
        }
    }

    padding: 11px;

    svg {
        overflow: visible !important;
    }
`;
