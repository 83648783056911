import React from 'react';

import Header from 'src/components/header';

import AppRouter from 'src/router/user-router';

export const WrappedAppRouter: React.FC = () => {
    return (
        <>
            <Header />
            <AppRouter />
        </>
    );
};
