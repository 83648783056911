import axios from 'axios';

import { ENDPOINT } from 'src/configuration/endpoints.ts';

import { AppThunk, resultError, resultSuccess } from 'src/store/root-reducer.models.ts';

export const brandInfer = (id: string) => async () => {
    try {
        const result = await axios.get(ENDPOINT.infer(id));
        return resultSuccess(result.data);
    } catch (e) {
        return resultError({ message: e });
    }
};

export const getBrandsThunk: AppThunk<{
    page: number;
    pageSize?: number;
    filtering?: string;
    sorting?: string;
}> = props => async () => {
    try {
        const result = await axios.get(
            ENDPOINT.brands(props.page, (props.pageSize = 10), props.filtering, props.sorting)
        );
        const pages = result.data.totalPages;
        return resultSuccess({ pages: parseInt(pages, 10), data: result.data.content });
    } catch (e) {
        return resultError({ message: e });
    }
};
