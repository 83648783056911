import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AppRoutes } from 'src/router/config';

import { BuyNowPayLater } from 'src/screens/buy-now-pay-later';
import { ChangeRequest } from 'src/screens/change-request';
import { ConsentLink } from 'src/screens/consent-link';
import { GeneralError } from 'src/screens/general-error';
import { PaymentConfirmation } from 'src/screens/payment-confirmation';
import { PaymentLink } from 'src/screens/payment-link';
import { SignIn } from 'src/screens/sign-in';

const PublicRoutes = () => {
    return (
        <Routes>
            <Route
                element={<PaymentLink />}
                key={`${AppRoutes.paymentLink}/:linkToken`}
                path={`${AppRoutes.paymentLink}/:linkToken`}
            />
            <Route element={<SignIn />} key={AppRoutes.customerSignIn} path={AppRoutes.customerSignIn} />
            <Route
                element={<BuyNowPayLater key={AppRoutes.buyNowPayLater} />}
                path={AppRoutes.buyNowPayLater}
            />
            <Route element={<PaymentConfirmation />} key="/c/:code" path="/c/:code" />
            <Route element={<GeneralError />} key={AppRoutes.generalError} path={AppRoutes.generalError} />
            <Route element={<ConsentLink />} key={AppRoutes.consentLink} path={AppRoutes.consentLink} />
            <Route
                element={<ChangeRequest />}
                key={AppRoutes.changeRequest}
                path={`${AppRoutes.changeRequest}/:virtualCustomerId`}
            />

            <Route
                element={<Navigate replace to={AppRoutes.buyNowPayLater} />}
                key={AppRoutes.buyNowPayLater}
                path="*"
            />
        </Routes>
    );
};

export default PublicRoutes;
