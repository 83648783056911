import { AppThunk, AppThunkPromise, ThunkResult } from 'src/store/root-reducer.models';
import { useThunkDispatch } from 'src/hooks/useThunkDispatch';
import { useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { saveResponseError } from 'src/store/user/reducer';

type UseThunkAction = <Args extends any>(
    thunkAction: AppThunk<Args>
) => [
    (
        ...p: Parameters<typeof thunkAction>
    ) => ReturnType<ReturnType<AppThunk<Parameters<typeof thunkAction>>>>,
    readonly [boolean, Dispatch<SetStateAction<boolean>>],
    readonly [string | undefined, Dispatch<SetStateAction<string | undefined>>],
];

export const useThunkAction: UseThunkAction = thunkAction => {
    const dispatch = useThunkDispatch();

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [thunk, setThunk] = useState<AppThunkPromise>();

    const isMountedRef = useMountedRef();

    const action = useCallback(
        (...p: Parameters<typeof thunkAction>) => {
            const _thunk = new Promise<ThunkResult>(resolve => {
                dispatch(thunkAction(...p)).then(r => {
                    if (!r.success) {
                        if (!isMountedRef.current) {
                            return;
                        }

                        dispatch(saveResponseError(r.error.message.response));
                        setError(
                            `${r.error.message}. Description: ${r.error.message.response?.data?.detail}.`
                        );
                    }
                    resolve(r);
                });
            });
            setThunk(_thunk);
            return _thunk;
        },
        [dispatch, isMountedRef, thunkAction]
    );

    // TODO
    // Prevent setStateOnUnmountedComponent error
    useEffect(() => {
        if (!thunk) {
            return;
        }

        let isMounted = true;

        setLoading(true);

        thunk.then(() => {
            if (!isMounted) {
                return;
            }

            setLoading(false);
        });

        return () => {
            isMounted = false;
        };
    }, [thunk, setLoading, setError]);

    return useMemo(
        () => [action, [isLoading, setLoading], [error, setError]],
        [error, isLoading, action, setError, setLoading]
    );
};
