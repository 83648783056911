import React from 'react';
import { StyledSubTitle, StyledTitle, StyledTitleWrapper } from 'src/components/title/title.styled';
import classNames from 'classnames';

interface TitleProps {
    title: string;
    titleStyled?: string;
    subtitle?: string;
    isChargee?: boolean;
}

export const Title: React.FC<TitleProps> = ({ title, titleStyled, subtitle, isChargee }) => {
    return (
        <StyledTitleWrapper>
            <StyledTitle $isChargee={isChargee} className={classNames(titleStyled)}>
                {title}
            </StyledTitle>
            {!isChargee && <StyledSubTitle>{subtitle}</StyledSubTitle>}
        </StyledTitleWrapper>
    );
};
