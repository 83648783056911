import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors.ts';
import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledContinueButton = styled.div`
    align-self: center;
    margin-top: 29px;
`;

export const StyledCancelButton = styled.div`
    align-self: center;
    margin: 12px 0 0;
`;

export const StyledInputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .input {
        margin-bottom: ${units.p26}px;
    }
`;

export const StyledTitle = styled.div`
    margin: 35px 0;
    ${AppTypography.chargeeRegularText};
    font-size: 21px;
    font-weight: bold;
    text-align: center;
`;

export const StyledCardRowText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    ${AppTypography.chargeeBrandName};
    color: ${AppColors.black000};
    font-size: 12px;

    span {
        align-items: center;
        display: inline-flex;
        padding-top: 6px;
    }

    .card-number {
        input {
            background-color: #ffffff;
            padding-right: 5px;
            border-radius: 6px;
            font-size: 14px;
        }
        fieldset {
            border-radius: 6px;
        }
    }

    .exp-cvv-text {
        width: 50px;
        input {
            background-color: #ffffff;
            padding-right: 5px;
            border-radius: 8px;
        }
        fieldset {
            border-radius: 6px;
        }
    }

    .input {
        width: auto;
    }
`;

export const StyledBody = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px 18px;
    margin: 10px 0 30px;
    background-color: #e5e5e5;
    border-radius: 10px;

    .card-icon {
        align-self: unset;
        border: none;
        margin-left: -10px;
    }
    .MuiFormControl-root.MuiTextField-root.card-number {
        width: 100%;
        input {
            text-align: left;
            padding: 12px 0 11px 8px;
        }
    }

    .MuiFormControl-root.MuiTextField-root.exp-cvv-text {
        width: 75px;
        input {
            text-align: center;
            padding: 12px 0 11px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        top: -8px;
    }

    .MuiFormHelperText-root.Mui-error {
        width: max-content;
        margin-left: -12px;
    }
`;

export const StyledCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${AppTypography.regularText};
    color: ${AppColors.screenTitle};
    line-height: 16px;

    .MuiInputBase-input {
        ${AppTypography.regularText};
        color: ${AppColors.screenTitle};
        line-height: 16px;
        padding: 6px 0 2px;
        text-align: right;
    }
`;

export const StyledConfirmText = styled.div`
    ${AppTypography.chargeeBrandName};
    font-size: 12px;

    span {
        font-weight: bold;
    }
`;
