import axios from 'axios';

import { ENDPOINT } from 'src/configuration/endpoints';

import {
    CancelPaymentDTO,
    CreateChangePaybackDTO,
    CreatePaymentDTO,
    PaymentMode,
    PreparePayment,
} from 'src/store/payment/models';
import { saveCreatePaymentResponse, savePreparePaymentResponse } from 'src/store/payment/reducer';
import { AppThunk, resultSuccess } from 'src/store/root-reducer.models';

export const createPayment: AppThunk<{ payment: CreatePaymentDTO }> =
    ({ payment }) =>
    async dispatch => {
        try {
            const result = await axios.post(ENDPOINT.payments, payment);
            dispatch(saveCreatePaymentResponse(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const preparePayment: AppThunk<{ payment: PreparePayment }> =
    ({ payment }) =>
    async dispatch => {
        try {
            const result = await axios.post(ENDPOINT.preparePayments, payment);
            dispatch(savePreparePaymentResponse(result.data));
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const cancelPayment: AppThunk<{ payment: CancelPaymentDTO }> =
    ({ payment }) =>
    async () => {
        try {
            const result = await axios.put(ENDPOINT.cancelPayment, payment);
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const getFinancingOptions: AppThunk<{
    amount: string;
    paymentMode: PaymentMode;
    customerId?: string;
    bankAccountId?: string;
}> =
    ({ amount, paymentMode, customerId, bankAccountId }) =>
    async () => {
        try {
            const result = await axios.get(
                ENDPOINT.financingOptions({ amount, mode: paymentMode, customerId, bankAccountId })
            );
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };
export const setPaymentLinkConsent: AppThunk<{ paymentLinkToken: string; isConsent: boolean }> =
    ({ paymentLinkToken, isConsent }) =>
    async () => {
        try {
            const result = await axios.post(ENDPOINT.consent(paymentLinkToken), isConsent, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const paybackPayment: AppThunk<CreateChangePaybackDTO> = payback => async () => {
    try {
        const result = await axios.post(ENDPOINT.paybackPayments, payback);
        return resultSuccess(result.data);
    } catch (e) {
        return { success: false, error: { message: e } };
    }
};
