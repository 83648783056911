import React from 'react';

import { getApplicationConfig } from 'src/configuration/config';

import { TEXT_VARS } from 'src/i18n/en';

import { ApplicationConfig } from 'src/types/applicationConfig.type';

import { formatString } from 'src/utils/formatString';

import { StyledCopyright } from './copyright.styled';

export const Copyright = () => {
    const appConfig: ApplicationConfig = getApplicationConfig();

    return (
        <StyledCopyright>{formatString(TEXT_VARS.COMMON_TEXT.COPYRIGHTS, appConfig.appTld)}</StyledCopyright>
    );
};
