import React, { useEffect, useState } from 'react';
import { StyledExtendable } from 'src/utils/sharedModels';
import Lottie from 'react-lottie';
import { StyledLoader } from 'src/components/loader/loader.styled';

import animationData from 'src/assets/img/lottie/loader.json';

interface LoaderProps {
    isShowing: boolean;
    relative?: boolean;
    withoutBackground?: boolean;
    delayTimeMilliseconds?: number;
}

const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const Loader: React.FC<LoaderProps & StyledExtendable> = props => {
    const [isLoaderHidden, setIsLoaderHidden] = useState(true);

    useEffect(() => {
        const _delay = props.delayTimeMilliseconds ? props.delayTimeMilliseconds : 0;
        setTimeout(() => {
            setIsLoaderHidden(false);
        }, _delay);
        return () => setIsLoaderHidden(true);
    }, [props.delayTimeMilliseconds]);

    return (
        <StyledLoader $isShowing={props.isShowing}>
            {isLoaderHidden ? null : props.isShowing ? (
                <Lottie options={defaultLottieOptions} width={300} height={300} />
            ) : (
                ''
            )}
        </StyledLoader>
    );
};
