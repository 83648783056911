import { AppButtonProps } from 'src/components/button/models';
import {
    StyledCancelButton,
    StyledContinueButton,
    StyledSignWithEmailButton,
    StyledSignWithFacebookButton,
    StyledSignWithGoogleButton,
    StyledSignWithPhoneButton,
    StyledSecondaryButton,
    StyledErrorButton,
    StyledTermsConditionsButton,
    StyledTermsCancelButton,
    StyledErrorReDesignButton,
} from 'src/components/button/styled';

export const AppButton = ({ appButtonType, ...buttonProps }: AppButtonProps) => {
    switch (appButtonType) {
        case 'SignWithGoogle': {
            return <StyledSignWithGoogleButton {...buttonProps} />;
        }
        case 'SignWithFacebook': {
            return <StyledSignWithFacebookButton {...buttonProps} />;
        }
        case 'SignWithEmail': {
            return <StyledSignWithEmailButton {...buttonProps} />;
        }
        case 'SignWithPhone': {
            return <StyledSignWithPhoneButton {...buttonProps} />;
        }
        case 'Continue': {
            return <StyledContinueButton {...buttonProps} />;
        }
        case 'Cancel': {
            return <StyledCancelButton {...buttonProps} />;
        }
        case 'Secondary': {
            return <StyledSecondaryButton {...buttonProps} />;
        }
        case 'Error': {
            return <StyledErrorButton {...buttonProps} />;
        }
        case 'TermsConditions': {
            return <StyledTermsConditionsButton {...buttonProps} />;
        }
        case 'TermsConditionsCancel': {
            return <StyledTermsCancelButton {...buttonProps} />;
        }
        case 'ErrorReDesign': {
            return <StyledErrorReDesignButton {...buttonProps} />;
        }
    }
};
