import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography.ts';
import { units } from 'src/theme/units.ts';

export const StyledTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    ${AppTypography.chargeeBrandName};
    font-size: 22px;
    line-height: ${units.p27}px;
`;

export const StyledAmount = styled.span`
    ${AppTypography.finOptionsBoldText};
    font-size: 22px;
    margin-left: 5px;
`;

export const StyledSummary = styled.span`
    margin-top: 78px;
    padding-left: 10px;
    ${AppTypography.chargeeBrandName};
    font-size: 18px;
`;

export const StyledTextBlocksWrapper = styled.div`
    margin-top: 12px;
    div:last-child {
        background: rgba(47, 168, 140, 0.33);
        font-weight: bold;
        font-family: 'HelveticaNeue-Bold', sans-serif;
    }
`;

export const StyledTextBlock = styled.div`
    padding: 10px;
    ${AppTypography.chargeeRegularText};
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #e7e8e9;
    border-radius: 10px;
    margin-bottom: 9px;
`;
