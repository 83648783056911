import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledSecureSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    svg:first-child {
        margin-right: 3px;
    }
`;

export const StyledSecure = styled.div`
    ${AppTypography.chargeeCopyrightText};
    color: ${AppColors.screenTitle};

    display: flex;
    justify-content: center;
    width: auto;
`;
