import { useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { getApplicationConfig } from 'src/configuration/config.ts';

import { useThunkAction } from 'src/hooks/useThunkAction.ts';

import { defaultSettings } from 'src/store/customer/actions.thunk.ts';

import { ApplicationConfig } from 'src/types/applicationConfig.type.ts';

type UseGoApp = () => [boolean, string | undefined];

const appConfig: ApplicationConfig = getApplicationConfig();

export const useGoApp: UseGoApp = () => {
    const [getWidgetSettingsAction, [isGetWidgetSettingLoading], [getWidgetSettingsError]] =
        useThunkAction(defaultSettings);

    useEffect(() => {
        getWidgetSettingsAction().then((r: any) => {
            if (!r.success) {
                return;
            }

            if (r.payload.forceAppDownload && isMobile && appConfig) {
                isIOS
                    ? window.location.replace(appConfig.iOSStore!)
                    : window.location.replace(appConfig.googleStore!);
            }
        });
    }, [getWidgetSettingsAction]);

    return [isGetWidgetSettingLoading, getWidgetSettingsError];
};
