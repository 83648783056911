import { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

import { AccountState } from 'src/store/accounts/models';
import { AppState } from 'src/store/app/models';
import { CardState } from 'src/store/cards/models';
import { ConfigState } from 'src/store/config/models';
import { InstitutionState } from 'src/store/institution/models';
import { PaymentState } from 'src/store/payment/models';
import { UserState } from 'src/store/user/models';

export interface ApplicationState {
    app: AppState;
    user: UserState;
    institutions: InstitutionState;
    accounts: AccountState;
    cards: CardState;
    payment: PaymentState;
    config: ConfigState;
    router: any;
}

export type AppError = { message: any };

export type ResultSuccess<T> = { success: true; payload: T };
export type ResultError = { success: false; error: AppError };
export type ThunkResult<P = {}> = ResultSuccess<P> | ResultError;
export type AppThunkPromise<T = {}> = Promise<ThunkResult<T>>;

export type AppThunk<Argument = void, R = {}, ExtraParams = any, Result = AppThunkPromise<R>> = (
    arg: Argument
) => AppThunkResult<Result, ExtraParams>;

export type AppThunkResult<Result = any, ExtraParamsType = any> = ThunkAction<
    Result,
    ApplicationState,
    ExtraParamsType,
    Action
>;

export type AppThunkDispatch = ThunkDispatch<ApplicationState, undefined, Action>;

export function resultSuccess(): ResultSuccess<{}>;
export function resultSuccess<T>(p: T): ResultSuccess<T>;

export function resultSuccess<T = {}>(payload?: T) {
    return { success: true, payload: payload ? payload : {} };
}

export const resultError = (error: AppError): ResultError => {
    console.error(error);
    return { success: false, error };
};
