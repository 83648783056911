import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledTitle = styled.div`
    margin: 35px 0;
    ${AppTypography.chargeeRegularText};
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
`;

export const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;

export const StyledTextSection = styled.div`
    margin-top: 30px;
`;

export const StyledText = styled.div`
    margin: 30px 0 50px 0;
    ${AppTypography.chargeeRegularText};
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
`;

export const StyledErrText = styled.div`
    margin: 30px 0;
    ${AppTypography.chargeeRegularText};
    color: ${AppColors.flushOrange};
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
`;
