import React from 'react';
import { TEXT_VARS } from 'src/i18n/en';
import { StyledPaymentCode } from './payment-code.styled';

export const PaymentCode: React.FC<{ paymentCode?: string | null }> = ({ paymentCode }) => {
    return (
        <StyledPaymentCode $paymentCode={paymentCode}>
            {paymentCode ? paymentCode : TEXT_VARS.ERRORS.NO_PAYMENT_CODE}
        </StyledPaymentCode>
    );
};
