import styled from '@emotion/styled';

export const StyledIframe = styled.iframe`
    height: 575px;
    margin: 20px 0;
`;

export const StyledButtonSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
