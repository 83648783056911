import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.STAGE,
    firebaseConfig: {
        apiKey: 'AIzaSyCq7MJcTj5yuo6uKdAAFj3PDpuZiF4FOZc',
        authDomain: 'billmybank-staging.firebaseapp.com',
        databaseURL: 'https://billmybank-staging.firebaseio.com',
        projectId: 'billmybank-staging',
        storageBucket: 'billmybank-staging.appspot.com',
        messagingSenderId: '399605251750',
        appId: '1:399605251750:web:94727915006f4ff80c3e67',
        measurementId: 'G-D06G5FQ3B1',
    },
    siteId: 'bmb-sdk-stage',
    baseApiUrl: 'https://api-stage.billmybank.com',
    fiServeWidgetBaseUrl: 'https://ais-ui-uat.fiservapps.com',
    showBnpl: true,
    sentryConfig: {
        dsn: 'https://c6da10034243471c8169bbb62c329db6@o503848.ingest.sentry.io/5589529',
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
    },
    iOSStore: 'https://apps.apple.com/us/app/billmybank/id6446295989',
    googleStore: 'https://play.google.com/store/apps/details?id=com.billmybank.app.stage',
    branchKey: 'key_test_nuiQW3x2VTZD14Dph4qw5hikzqjti2T1',
};

export default config;
