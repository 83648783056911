import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors.ts';
import { AppTypography } from 'src/theme/app-typography.ts';

export const StyledBankAccountComponent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 23px;

    .bank-img {
        min-width: 60px;
        margin-right: 30px;
    }

    .error {
        color: ${AppColors.errorText};
    }
`;

export const StyledBankAccountData = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .error-message {
        color: ${AppColors.errorText};
        opacity: 0.5;
        font-size: 10px;
    }
`;

export const StyledBorder = styled.div`
    margin: 10px 0;
    height: 0;
    border: solid 0.6px #707070;
`;

export const StyledLinkButton = styled.a`
    text-decoration: none;
    align-self: center;
    color: ${AppColors.bmbPrimary};
    font-size: 14px;
    cursor: pointer;
`;

export const StyledRowBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .error {
        color: ${AppColors.errorText};
    }
`;

export const StyledSubtitle = styled('span', {
    shouldForwardProp: prop => prop !== '$isChargee',
})<{ $isChargee: boolean }>`
    ${AppTypography.regularText};
    color: ${AppColors.primaryButtonTextColor};
    font-weight: 600;
    ${props => props.$isChargee && AppTypography.chargeeRegularText};
    font-size: ${props => (props.$isChargee ? '14px' : '')};
`;

export const StyledTextBlock = styled.div`
    ${AppTypography.regularText};
    color: ${AppColors.black000};

    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
`;
