import Lottie from 'react-lottie';

import animationData from 'src/assets/img/lottie/success.json';

import { StyledCheckMarkPopup, StyledText } from 'src/screens/get-the-app/components/check-mark-popup.styled';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};

export const CheckMarkPopup = ({ isConsentLinkFlow }: { isConsentLinkFlow: boolean }) => {
    const text = isConsentLinkFlow ? 'Consent processed. Thank you!' : 'Successfully processed';

    return (
        <StyledCheckMarkPopup>
            <Lottie height={300} options={defaultOptions} width={300} />
            <StyledText>{text}</StyledText>
        </StyledCheckMarkPopup>
    );
};
