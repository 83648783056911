import axios from 'axios';

import { ENDPOINT } from 'src/configuration/endpoints.ts';

import { AppThunk, resultSuccess } from 'src/store/root-reducer.models.ts';

export const mergeCustomer: AppThunk<{ virtualCustomerId: string; data: object }> =
    ({ virtualCustomerId, data }) =>
    async () => {
        try {
            const result = await axios.post(ENDPOINT.mergeCustomer(virtualCustomerId), data);
            return resultSuccess(result.data);
        } catch (e) {
            return { success: false, error: { message: e } };
        }
    };

export const defaultSettings: AppThunk = () => async () => {
    try {
        const result = await axios.get(ENDPOINT.widgetSettings);
        return resultSuccess(result.data);
    } catch (e) {
        return { success: false, error: { message: e } };
    }
};
