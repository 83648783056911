import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { StyledExtendable } from 'src/utils/sharedModels';
import {
    RightArrow,
    StyledBackButton,
    StyledBackButtonComponent,
} from 'src/components/back-button/back-button.styled';
import { useAppRoute } from 'src/hooks/useAppRoute';
import { AppRoutes } from 'src/router/config';

export const BackButton: React.FC<
    { routeUrl?: AppRoutes; action?: () => void; isHidden?: boolean } & StyledExtendable
> = ({ routeUrl, action, isHidden, ...rest }) => {
    const history = useNavigate();
    const route = useAppRoute();

    const handleBackButtonClick = useCallback(() => {
        if (action) {
            action();
            return;
        }

        routeUrl ? route(routeUrl) : history(-1);
    }, [action, history, route, routeUrl]);

    return (
        <StyledBackButtonComponent onClick={handleBackButtonClick} isHidden={isHidden}>
            <StyledBackButton
                edge={'start'}
                aria-label="go-back"
                {...rest}
                className={classNames('back-button', rest.className)}
            >
                <RightArrow />
            </StyledBackButton>
            Back
        </StyledBackButtonComponent>
    );
};
