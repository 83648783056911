import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledTitle = styled.div`
    text-align: center;
    margin: ${units.p42}px 0 ${units.p17}px;
    .styled-title {
        ${AppTypography.appInputTitle};
        font-size: 20px;
        font-weight: normal;
    }
`;

export const StyledConfirmButton = styled.div`
    align-self: center;
`;
