export const units = {
    p5: 5,
    p6: 6,
    p8: 8,
    p12: 12,
    p14: 14,
    p16: 16,
    p17: 17,
    p20: 20,
    p24: 24,
    p25: 25,
    p26: 26,
    p27: 27,
    p30: 30,
    p31: 31,
    p32: 32,
    p35_5: 35.5,
    p36_5: 36.5,
    p40: 40,
    p41_5: 41.5,
    p42: 42,
    p42_5: 42.5,
    p45: 45,
    p48: 48,
    p56: 56,
    p60: 60,
    p64: 64,
    p72: 72,
    p80: 80,
    p88: 88,
    p96: 96,
    p100: 100,
    p104: 104,
    p128: 128,
};
