import styled from '@emotion/styled';

export const StyledChargeeLogo = styled.div`
    margin: 100px 0;
    display: flex;
    width: fit-content;
    align-self: center;
    transform: scale(0.73);
`;

export const StyledTitleWrapper = styled.div`
    margin: 40px 0;
    align-self: center;
    width: fit-content;
`;
