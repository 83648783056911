import { css } from '@emotion/react';

import { AppColors } from 'src/theme/app-colors';

export const AppTypography = {
    h3: css({
        fontFamily: 'NunitoSans',
        fontSize: '24px',
        fontWeight: 300,
        letterSpacing: -0.55,
        color: AppColors.screenTitle,
    }),
    h4: css({
        fontFamily: 'NunitoSans',
        fontSize: '14px',
        fontWeight: 300,
        height: '19px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.36,
        letterSpacing: 'normal',
        color: AppColors.headerTitle,
    }),
    primaryButtonText: css({
        fontFamily: 'MavenPro-Medium',
        fontSize: '18px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: '-0.41px',
        color: AppColors.primaryButtonTextColor,
    }),
    smallText: css({
        fontFamily: 'ArialMT',
        fontSize: '14px',
        letterSpacing: -0.32,
        color: AppColors.screenTitle,
    }),

    institutionTitle: css({
        fontFamily: 'MavenPro-Medium',
        fontSize: '11px',
        letterSpacing: -0.25,
        color: AppColors.black000,
    }),

    appInputTitle: css({
        fontFamily: 'ArialMT',
        fontWeight: 'bold',
        fontSize: '10px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.1,
        letterSpacing: 'normal',
        color: AppColors.screenTitle,
    }),
    blockTitle: css({
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '20px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        color: AppColors.screenTitle,
    }),
    infoText: css({
        fontFamily: 'SegoeUI',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: AppColors.primaryButtonTextColor,
    }),
    regularText: css({
        fontFamily: 'SourceSansPro-Regular',
        fontSize: '12px',
    }),
    confirmationInfoText: css({
        fontFamily: 'SourceSansPro-Regular',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.45,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: AppColors.screenTitle,
    }),
    chargeeBrandName: css({
        fontFamily: 'HelveticaNeue',
        fontSize: '16px',
        fontWeight: 'normal',
        letterSpacing: 'normal',
    }),
    chargeeRegularText: css({
        fontFamily: 'HelveticaNeue',
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: 'normal',
        color: AppColors.black000,
    }),
    chargeeRegularThinText: css({
        fontFamily: 'HelveticaNeue-Thin',
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: 'normal',
        color: AppColors.black000,
    }),
    chargeeCopyrightText: css({
        fontFamily: 'HelveticaNeue-Thin',
        fontSize: '14px',
        fontWeight: 'bold',
        letterSpacing: 'normal',
        color: AppColors.black000,
    }),
    finOptionsBoldText: css({
        fontFamily: 'HelveticaNeue-Bold',
        fontSize: '12px',
        letterSpacing: 'normal',
        color: AppColors.black000,
    }),
    finOptionsRegularText: css({
        fontFamily: 'HelveticaNeue-Regular',
        fontSize: '12px',
        letterSpacing: 'normal',
        color: AppColors.black000,
    }),
};
