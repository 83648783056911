import styled from '@emotion/styled';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useMountedRef } from 'src/hooks/useMountedRef';

import { StyledExtendable } from 'src/utils/sharedModels';

import { ImgProps } from './models';

const defaultImageUrl = 'https://via.placeholder.com';

const StyledPlaceholder = styled.div<{
    width?: string | number;
    height?: number | string;
    $isError?: boolean;
}>`
    height: ${({ height }) => height && `${height}px`};
    width: ${({ width }) => width && `${width}px`};

    border-radius: 2px;

    position: static;
    overflow: hidden;

    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.08) 0,
        rgba(0, 0, 0, 0.15) 15%,
        rgba(0, 0, 0, 0.08) 30%
    );
    background-size: 1200px 100%;

    ${({ $isError }) => (!$isError ? `animation: placeholderShimmer 2s linear` : ``)};
    animation-iteration-count: infinite;

    @keyframes placeholderShimmer {
        0% {
            background-position: -1200px 0;
        }

        100% {
            background-position: 1200px 0;
        }
    }
`;

type ImgSrc = string;

const appImages = new Map<ImgSrc, HTMLImageElement>();

type Props = ImgProps & StyledExtendable;

export const Img = ({ src, ...props }: Props) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(!src);
    const isMountedRef = useMountedRef();

    const [imageSrc, setImageSrc] = useState(src && src.length !== 0 ? src : defaultImageUrl);

    useEffect(() => {
        setImageSrc(src || defaultImageUrl);
    }, [src, setImageSrc]);

    useEffect(() => {
        if (appImages.get(imageSrc)) {
            setLoading(false);
            setError(false);
            return;
        }

        const img = new Image();

        img.onload = () => {
            if (!isMountedRef.current) {
                return;
            }
            setLoading(false);
            setError(false);

            appImages.set(imageSrc, img);
        };

        img.onerror = () => {
            if (!isMountedRef.current) {
                return;
            }

            setError(true);
            setImageSrc(defaultImageUrl);
        };

        img.src = imageSrc;
    }, [imageSrc, isMountedRef]);

    return isLoading ? (
        <StyledPlaceholder
            $isError={error}
            className={classNames(props.className)}
            height={props.height}
            width={props.width}
        />
    ) : (
        <img
            src={imageSrc}
            {...props}
            alt={props.alt ? props.alt : src}
            className={classNames(props.className, 'global-image-class')}
            data-error={imageSrc === defaultImageUrl}
        />
    );
};
