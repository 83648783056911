import { History } from 'history';
import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppRoutes } from 'src/router/config';

export const useAppRoute = <QueryParams = {}>() => {
    const navigate = useNavigate();
    const l = useLocation();

    return useCallback(
        (
            url: AppRoutes | string,
            options?: History,
            method: 'push' | 'replace' = 'push',
            query?: QueryParams
        ) => {
            let search = new URLSearchParams(query || {}).toString();
            search = search ? `?${search}` : '';
            return navigate(`${url}${search}`, {
                ...(options as object),
                ...(method === 'replace' ? { replace: true } : {}),
                state: { from: l.pathname },
            });
        },
        [l.pathname, navigate]
    );
};
