import styled from '@emotion/styled';
import { AppBar, AppBarProps, Toolbar } from '@mui/material';

import { AppColors } from 'src/theme/app-colors';
import { TOP_BAR_HEIGHT } from 'src/theme/global-styles';

export const CenterBlock = styled.div`
    .logo-button {
        padding: 0;
        width: 100%;
        height: 100%;
    }
`;

export const StyledToolbar = styled(Toolbar)`
    min-height: ${TOP_BAR_HEIGHT}px;
    padding: 0;
    display: flex;
    justify-content: center;
`;

const StyledAppBar = (props: AppBarProps) => <AppBar color="primary" position="sticky" {...props} />;

export const StyledHeader = styled(StyledAppBar)`
    box-shadow: 0 0;
    width: 375px;
    align-self: center;

    z-index: 999;

    &.MuiAppBar-colorPrimary {
        background: ${AppColors.white};
    }
`;
