import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 52px;
    width: 344px;
    height: 469px;
    background-color: #e2ce65;
    border-radius: 16px;
    z-index: 1001;
    pointer-events: all;
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`;

export const StyledLabelText = styled.span`
    margin: 40px 21px 0;
    ${AppTypography.chargeeRegularText};
    font-weight: bold;
`;

export const StyledBodyText = styled.span`
    margin: 61px 21px 40px;
    ${AppTypography.chargeeRegularThinText};
    font-weight: normal;
    font-size: 18px;
`;

export const StyledInfoLayout = styled.div`
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(127, 127, 127);
`;
