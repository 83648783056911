import { FormControl } from '@mui/material';
import { FinancingOptions } from 'src/components/financing-options-radio/components/financing-options';
import React from 'react';
import {
    StyledFormLabel,
    StyledRadioContainer,
} from 'src/components/financing-options-radio/financing-options-radio.styled';
import { TEXT_VARS } from 'src/i18n/en';

export const FinancingOptionsRadio: React.FC<{
    financingOption: string;
    setFinancingOption: React.Dispatch<React.SetStateAction<string>>;
    financingOptions: any;
}> = ({ financingOptions, financingOption, setFinancingOption }) => {
    return (
        <StyledRadioContainer>
            <FormControl component="fieldset">
                <StyledFormLabel>{TEXT_VARS.TITLE.SELECT_FIN_OPTION}</StyledFormLabel>
                {financingOptions && (
                    <FinancingOptions
                        financingOption={financingOption}
                        setFinancingOption={setFinancingOption}
                        options={financingOptions}
                    />
                )}
            </FormControl>
        </StyledRadioContainer>
    );
};
