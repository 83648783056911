import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';

export const StyledBody = styled.div`
    padding: 20px;

    .info-text {
        ${AppTypography.infoText};
        font-size: 18px;
    }

    .payment-info-wrapper {
        display: flex;
        margin: 20px 0;
    }

    .payment-info {
        display: flex;
        flex-direction: column;
        ${AppTypography.infoText};
        font-size: 18px;
    }

    .amount-value {
        font-weight: bold;
    }
`;

export const StyledButtonSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .button-negative {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: solid 1px #009789;
        margin: 10px 0 0;
    }

    .button-negative:disabled {
        opacity: 0.6;
    }

    .button-aff {
        margin: 10px 0;
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: solid 1px #707070;
        background-color: #009789;
    }

    .button-aff:disabled {
        opacity: 0.6;
    }

    .cancel-text {
        font-family: NunitoSans, serif;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -1.14px;
        text-align: center;
        color: #009789;
    }
    .save-text {
        font-family: NunitoSans, serif;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -1.14px;
        text-align: center;
        color: #ffffff;
    }
`;
