import { Institution, InstitutionCredentials, InstitutionState } from 'src/store/institution/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const INITIAL_STATE: InstitutionState = {};

const institutionReducer = createSlice({
    name: 'InstitutionState',
    initialState: INITIAL_STATE,
    reducers: {
        clearInstitutionState: () => INITIAL_STATE,

        saveInstitutions: (state, action: PayloadAction<Institution[]>) => {
            state.allInstitutions = action.payload;
        },
        saveInstitutionCredentials: (state, action: PayloadAction<InstitutionCredentials[]>) => {
            state.selectedInstitutionCredentials = action.payload;
        },
        saveSelectedInstitution: (state, action: PayloadAction<string>) => {
            state.selectedInstitution = action.payload;
        },
    },
});

export const {
    clearInstitutionState,
    saveInstitutions,
    saveInstitutionCredentials,
    saveSelectedInstitution,
} = institutionReducer.actions;
export default institutionReducer.reducer;
