import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreditCardEmptyForm } from 'src/components/add-credit-card';
import { Alerts } from 'src/components/alerts';
import { BackButton } from 'src/components/back-button';
import { AppButton } from 'src/components/button';
import { CreditCardComponent } from 'src/components/credit-card';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';
import { StepBar } from 'src/components/step-bar';
import { Title } from 'src/components/title';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useMountedRef } from 'src/hooks/useMountedRef';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import {
    StyledBankAccounts,
    StyledBodySection,
    StyledBodyText,
    StyledBorderBetweenCards,
    StyledConfirmButton,
    StyledCancelButton,
    StyledSubTitle,
    StyledStepBar,
    StyledSubTitleSection,
} from 'src/screens/select-your-card/select-your-card';

import { selectSelectedBankAccount } from 'src/store/accounts/selectors';
import { Card } from 'src/store/cards/models';
import { clearCards, saveSelectedCard } from 'src/store/cards/reducer';
import { selectSelectedCard } from 'src/store/cards/selectors';
import { cancelPayment } from 'src/store/payment/actions.thunks';
import { selectPreparePaymentResponse } from 'src/store/payment/selectors';
import { addCreditCard, deleteCreditCard, getCurrentCustomer, signOut } from 'src/store/user/actions.thunks';
import { selectCustomer, selectQueryParams } from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

const CANCEL_PAYMENT = 'cancelPaymentAction';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';

const sx = {
    background: '#4ad1ca',
    color: 'white',
    marginBottom: '5px',

    '&:hover': {
        background: 'lightGray',
    },
};

export const SelectYourCard = () => {
    const route = useAppRoute();
    const dispatch = useDispatch();
    const isMountedRef = useMountedRef();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const customer = useSelector(selectCustomer);
    const selectedCard = useSelector(selectSelectedCard);
    const preparePaymentResponse = useSelector(selectPreparePaymentResponse);
    const queryParams = useSelector(selectQueryParams);
    const selectedBankAccount = useSelector(selectSelectedBankAccount);

    const [isFormAddNewCardDisplayed, setIsFormAddNewCardDisplayed] = useState<boolean>(false);
    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState<boolean>(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState<boolean>(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');

    const [
        getCurrentUserAction,
        [isGetCurrentUserActionLoading],
        [currentCustomerThunkError, setCurrentCustomerThunkError],
    ] = useThunkAction(getCurrentCustomer);

    const [
        addCreditCardThunkAction,
        [isLoadingAddCreditCardThunkAction],
        [addCreditCardThunkError, setAddCreditCardThunkError],
    ] = useThunkAction(addCreditCard);
    const [
        deleteCreditCardAction,
        [isLoadingDeleteCreditCardAction],
        [deleteCreditCardError, setDeleteCreditCardError],
    ] = useThunkAction(deleteCreditCard);

    const [cancelPaymentAction, [isCancelPaymentLoading], [cancelPaymentError, setCancelPaymentError]] =
        useThunkAction(cancelPayment);

    const [errorCardTextMessage, setErrorCardTextMessage] = useState('');

    const handlePlusIconButtonClick = useCallback(() => {
        setIsFormAddNewCardDisplayed(true);
    }, []);

    const handleSelectCreditCard = useCallback(
        (card: Card) => {
            dispatch(saveSelectedCard(card));
        },
        [dispatch]
    );

    const _getCurrentUser = useCallback(async () => {
        return await getCurrentUserAction().then(r => {
            if (!isMountedRef.current) return r;

            if (!r.success) {
                if (r.error.message.response?.status >= 500) {
                    setCurrentCustomerThunkError(TEXT_VARS.ERRORS.SERVER_ERROR);
                }
            }
            setIsLoading(false);

            return r;
        });
    }, [getCurrentUserAction, isMountedRef, setCurrentCustomerThunkError]);

    const handleSaveCreditCardAction = useCallback(
        (customerId: string, cardData: any) => {
            addCreditCardThunkAction({ customerId, data: cardData }).then(r => {
                if (!isMountedRef.current) {
                    return;
                }

                if (!r.success) {
                    if (r.error.message.response?.status >= 500) {
                        setAddCreditCardThunkError(TEXT_VARS.ERRORS.SERVER_ERROR);
                    } else if (r.error.message.response?.status >= 400) {
                        setAddCreditCardThunkError(undefined);
                        setErrorCardTextMessage(
                            r.error.message.response.data.detail
                                ? r.error.message.response.data.detail
                                : TEXT_VARS.COMMON_TEXT.BAD_CARD_DATA_PLEASE_ENTER_A_VALID
                        );
                    }

                    return;
                }
                dispatch(saveSelectedCard(r.payload as Card));
                setIsFormAddNewCardDisplayed(false);
                _getCurrentUser();
            });
        },
        [addCreditCardThunkAction, isMountedRef, dispatch, _getCurrentUser, setAddCreditCardThunkError]
    );

    const handleDeleteCreditCardClick = useCallback(
        (cardId: string) => {
            if (!customer) return;

            deleteCreditCardAction({ customerId: customer.id, cardId }).then(r => {
                if (!isMountedRef.current) {
                    return;
                }
                if (!r.success) {
                    if (r.error.message.response?.status >= 500) {
                        setDeleteCreditCardError(TEXT_VARS.ERRORS.SERVER_ERROR);
                    }
                }

                if (selectedCard && cardId === selectedCard.id) {
                    dispatch(clearCards());
                }
                _getCurrentUser();
            });
        },
        [
            customer,
            deleteCreditCardAction,
            isMountedRef,
            selectedCard,
            _getCurrentUser,
            setDeleteCreditCardError,
            dispatch,
        ]
    );

    const handleContinueClick = useCallback(async () => {
        if (!selectedCard) return;

        route(AppRoutes.customerConfirmation);
    }, [selectedCard, route]);

    const isButtonDisabled = useMemo(() => {
        return selectedCard;
    }, [selectedCard]);

    const CardComponent = useMemo(() => {
        if (!customer) return;

        return (
            customer.cards?.length > 0 &&
            customer.cards.map((card, index) => (
                <Fragment key={card.id}>
                    <CreditCardComponent
                        creditCard={card}
                        handleDeleteButtonClick={handleDeleteCreditCardClick}
                        isRadioButtonChecked={selectedCard?.id === card.id}
                        onClick={handleSelectCreditCard}
                    />
                    {customer.cards?.length !== index + 1 && <StyledBorderBetweenCards />}
                </Fragment>
            ))
        );
    }, [customer, handleDeleteCreditCardClick, handleSelectCreditCard, selectedCard]);

    useEffect(() => {
        if (customer && customer.cards?.length === 0) {
            setIsFormAddNewCardDisplayed(true);
        }
    }, [customer]);

    useEffect(() => {
        _getCurrentUser();
    }, [_getCurrentUser]);

    useEffect(() => {
        setCurrentCustomerThunkError(undefined);
        setAddCreditCardThunkError(undefined);
        setDeleteCreditCardError(undefined);
        setCancelPaymentError(undefined);
    }, [
        setCurrentCustomerThunkError,
        setAddCreditCardThunkError,
        setDeleteCreditCardError,
        setCancelPaymentError,
    ]);

    const handleInfoLayoutActionClick = useCallback(() => {
        window.location.reload();
    }, []);

    const isErrorDisplayed: boolean = useMemo(() => {
        return !!(currentCustomerThunkError || addCreditCardThunkError || deleteCreditCardError);
    }, [currentCustomerThunkError, addCreditCardThunkError, deleteCreditCardError]);

    const handleOpenCancelPaymentPopup = useCallback(() => {
        setIsCancelPopupDisplayed(true);
    }, []);

    const cancelPaymentPopupTitle = useMemo(() => {
        return isCancelPaymentSuccess
            ? TEXT_VARS.COMMON_TEXT.YOUR_ORDER_CANCELED
            : TEXT_VARS.COMMON_TEXT.CANCEL_PURCHASE;
    }, [isCancelPaymentSuccess]);

    const cancelPaymentButtonClick = useCallback(() => {
        if (!preparePaymentResponse) {
            return;
        }

        if (!queryParams) {
            setAlertErrorMessage('QueryParams unavailable, but expected');
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                description: queryParams.description,
                merchantOrderId: queryParams.orderId,
                bankAccountId: selectedBankAccount?.id,
            },
        }).then(r => {
            if (!r.success) return;
            setIsCancelPaymentSuccess(true);
        });
    }, [selectedBankAccount?.id, cancelPaymentAction, preparePaymentResponse, queryParams]);

    const handler = useCallback(
        async (event: MessageEvent) => {
            {
                if (event.data.cancelPayment === CANCEL_PAYMENT) {
                    await cancelPaymentButtonClick();
                    await signOut();
                    window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
                }
            }
        },
        [cancelPaymentButtonClick]
    );
    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [handler]);

    return (
        <>
            <StyledPageWithPaddingContainer>
                <Loader
                    isShowing={
                        isLoading ||
                        isLoadingAddCreditCardThunkAction ||
                        isGetCurrentUserActionLoading ||
                        isLoadingDeleteCreditCardAction ||
                        isCancelPaymentLoading
                    }
                />
                <BackButton routeUrl={AppRoutes.customerSelectYourBankAccount} />
                <Title subtitle={TEXT_VARS.TITLE.OR_ADD_NEW_ONE} title={TEXT_VARS.TITLE.SELECT_YOUR_CARD} />
                <StyledStepBar>
                    <StepBar step={3} />
                </StyledStepBar>
                {isFormAddNewCardDisplayed && customer && (
                    <CreditCardEmptyForm
                        customerId={customer.id}
                        errorCardTextMsg={errorCardTextMessage}
                        handleDeleteButtonAction={setIsFormAddNewCardDisplayed}
                        handleSaveButtonAction={handleSaveCreditCardAction}
                        setErrorCardTextMsg={setErrorCardTextMessage}
                    />
                )}
                <StyledSubTitleSection>
                    <StyledSubTitle>{TEXT_VARS.TITLE.CARDS}</StyledSubTitle>
                    <Fab aria-label="add" onClick={handlePlusIconButtonClick} size="small" sx={sx}>
                        <AddIcon />
                    </Fab>
                </StyledSubTitleSection>
                <StyledBodySection>
                    <StyledBankAccounts>{CardComponent}</StyledBankAccounts>

                    {customer && customer.cards?.length === 0 && (
                        <StyledBodyText>{TEXT_VARS.COMMON_TEXT.PLEASE_ADD_A_CARD_TO_CONTINUE}</StyledBodyText>
                    )}
                </StyledBodySection>
                {alertErrorMessage && <Alerts alertType="error" message={alertErrorMessage} />}

                <StyledConfirmButton>
                    <AppButton
                        appButtonType="Continue"
                        disabled={!isButtonDisabled}
                        onClick={handleContinueClick}
                    >
                        {TEXT_VARS.BUTTON.CONTINUE}
                    </AppButton>
                </StyledConfirmButton>
                <StyledCancelButton>
                    <AppButton appButtonType="Cancel" onClick={handleOpenCancelPaymentPopup}>
                        {TEXT_VARS.BUTTON.CANCEL}
                    </AppButton>
                </StyledCancelButton>
            </StyledPageWithPaddingContainer>
            {isCancelPopupDisplayed && !cancelPaymentError && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={cancelPaymentButtonClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    titleText={cancelPaymentPopupTitle}
                    type="error"
                />
            )}

            {isErrorDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </>
    );
};
