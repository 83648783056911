import styled from '@emotion/styled';

import { AppColors } from 'src/theme/app-colors';

export const StyledCreditCardIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 46px;
    align-self: flex-end;
    border: solid 1px ${AppColors.primaryButtonTextColor};

    .credit-card-svg {
        height: 54px;
    }
`;
