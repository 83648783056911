import styled from '@emotion/styled';

import { InfoActionLayoutType } from 'src/components/info-action-layout';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledInfoLayout = styled.div<{ type: InfoActionLayoutType }>`
    z-index: 999;
    pointer-events: none;
    opacity: 0.6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => (props.type === 'success' ? AppColors.successLayout : AppColors.black000)};
`;

export const StyledBodySection = styled.div`
    z-index: 1000;
    pointer-events: all;
    position: absolute;
    left: 0;
    right: 0;
    top: 51.5px;
`;

export const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    ${AppTypography.regularText};
    font-size: 20px;
    color: ${AppColors.screenTitle};
    background: ${AppColors.white};
    width: 311px;
    z-index: 999;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 30px;
`;

export const StyledBodyText = styled.div`
    padding-top: 100px;
    width: 254px;
    align-self: center;
    color: ${AppColors.black000};

    .text-subtitle {
        text-align: center;
    }

    .text-body {
        overflow: auto;
        padding: 23px 26px;
        line-height: 26px;
        max-height: 450px;
        white-space: break-spaces;
        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        text-align: center;
        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }
`;

export const StyledButtonWrapper = styled.div`
    align-self: center;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    button:first-child {
        width: 254px;
    }
`;

export const StyledSVG = styled.div`
    position: absolute;
    z-index: 1000;
    width: 80px;
    left: 0;
    right: 0;
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
`;

export const StyledLinkButton = styled.button`
    margin-top: 18px;
    color: #707070;
    border: none;
    background-color: unset;
    text-decoration: underline;
    cursor: pointer;

    :hover {
        color: green;
    }
`;
