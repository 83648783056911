import appLogoBillmybank from '@flavours/billmybank/assets/img/appLogoText.png';
import appLogoSvgBillmybank from '@flavours/billmybank/assets/img/appLogoText.png';
import billmybankCommonConfig from '@flavours/billmybank/config/config';
import billmybankDevConfig from '@flavours/billmybank/config/config-dev';
import billmybankProdConfig from '@flavours/billmybank/config/config-prod';
import billmybankSandboxConfig from '@flavours/billmybank/config/config-sandbox';
import billmybankStageConfig from '@flavours/billmybank/config/config-stage';
import appLogoChargee from '@flavours/chargee/assets/img/appLogoText.png';
import appLogoSvgChargee from '@flavours/chargee/assets/img/appLogoText.png';
import chargeeCommonConfig from '@flavours/chargee/config/config';
import chargeeDevConfig from '@flavours/chargee/config/config-dev';
import chargeeProdConfig from '@flavours/chargee/config/config-prod';
import chargeeSandboxConfig from '@flavours/chargee/config/config-sandbox';
import chargeeStageConfig from '@flavours/chargee/config/config-stage';

import { ConfigCommon, ConfigEnv } from 'src/types/config.interface';

type Env = 'dev' | 'prod' | 'sandbox' | 'stage';
type Flavour = 'billmybank' | 'chargee';

interface Configurations extends Record<Env, ConfigEnv> {
    common: ConfigCommon;
}

type AppConfig = Record<Flavour, Configurations>;

const appConfig: AppConfig = {
    billmybank: {
        common: billmybankCommonConfig,
        dev: billmybankDevConfig,
        prod: billmybankProdConfig,
        sandbox: billmybankSandboxConfig,
        stage: billmybankStageConfig,
    },

    chargee: {
        common: chargeeCommonConfig,
        dev: chargeeDevConfig,
        prod: chargeeProdConfig,
        sandbox: chargeeSandboxConfig,
        stage: chargeeStageConfig,
    },
};

const FLAVOUR: Flavour = import.meta.env.REACT_APP_FLAVOUR || 'billmybank';
const TARGET_ENV: Env = import.meta.env.REACT_APP_TARGET_ENV || 'prod';

// eslint-disable-next-line
console.log(`loading ${FLAVOUR}/${TARGET_ENV} in ${process.env.NODE_ENV} mode`);

const commonConfig = appConfig[FLAVOUR].common;
const envConfig = appConfig[FLAVOUR][TARGET_ENV];

const isBMB = FLAVOUR === 'billmybank';

export const getApplicationConfig = () => ({
    ...commonConfig,
    ...envConfig,
    images: {
        appLogoText: isBMB ? appLogoBillmybank : appLogoChargee,
        appLogoTextSvg: isBMB ? appLogoSvgBillmybank : appLogoSvgChargee,
    },
});
