import axios from 'axios';
import { store } from 'src/store';
import { getApplicationConfig } from 'src/configuration/config';
import { saveResponseError } from 'src/store/user/reducer';
import { ApplicationConfig } from 'src/types/applicationConfig.type';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
const { baseApiUrl }: ApplicationConfig = getApplicationConfig();
axios.defaults.baseURL = baseApiUrl;

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
    const onRequestSuccess = async (config: any) => {
        const _store = store.getState();
        store.dispatch(saveResponseError(undefined));
        const authToken = _store.user.generatedJWTToken?.idToken;

        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }

        return config;
    };
    const onResponseSuccess = (response: any) => response;
    const onResponseError = (err: any) => {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 403 || status === 401) {
            onUnauthenticated();
        }

        return Promise.reject(err);
    };
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
