import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import RightArrowSVG from 'src/assets/img/rightArrow.svg?react';

import { AppColors } from 'src/theme/app-colors';
import { AppTypography } from 'src/theme/app-typography';

export const StyledBackButtonComponent = styled.div<{ isHidden?: boolean }>`
    display: flex;
    align-items: center;
    ${AppTypography.primaryButtonText};
    font-size: 16px;
    visibility: ${props => {
        return props.isHidden ? 'hidden' : 'visible';
    }};
`;

export const StyledBackButton = styled(IconButton)`
    width: 56px;
    height: 56px;

    &.MuiButtonBase-root {
        background: white;
    }
`;

export const RightArrow = styled(RightArrowSVG)`
    transform: rotatey(180deg);
    width: 14px;
    height: 14px;
    fill: ${AppColors.black};
`;
