import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.PROD,
    firebaseConfig: {
        apiKey: 'AIzaSyBf4trvTkm5-hc_wZSqxA3vzPVmDAyfQZU',
        authDomain: 'billmybank-prod.firebaseapp.com',
        databaseURL: 'https://billmybank-prod.firebaseio.com',
        projectId: 'billmybank-prod',
        storageBucket: 'billmybank-prod.appspot.com',
        messagingSenderId: '1020961162048',
        appId: '1:1020961162048:web:f871c1ef03a09c5c7f70a5',
        measurementId: 'G-MJJEFE1KHR',
    },
    siteId: 'bmb-sdk-prod',
    baseApiUrl: 'https://api.billmybank.com',
    fiServeWidgetBaseUrl: 'https://ais-ui.fiservapps.com',
    showBnpl: false,
    sentryConfig: {
        dsn: 'https://c6da10034243471c8169bbb62c329db6@o503848.ingest.sentry.io/5589529',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.1,
    },
    iOSStore: 'https://apps.apple.com/us/app/billmybank/id6446296196',
    googleStore: 'https://play.google.com/store/apps/details?id=com.billmybank.app',
    branchKey: 'key_live_kyaR68BZQQ5E0ZsafXtzMhfpEBosg1VU',
};

export default config;
