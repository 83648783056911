import * as React from "react";
const SvgRoundButtonEmpty = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 30.666668 30.666668", height: 40, width: 40, xmlSpace: "preserve", id: "svg2", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clipPath18" }, /* @__PURE__ */ React.createElement("path", { style: {
  clipRule: "evenodd"
}, id: "path16", d: "M 23,11.5 C 23,5.148726 17.851274,0 11.5,0 5.148726,0 0,5.148726 0,11.5 0,17.851274 5.148726,23 11.5,23 17.851274,23 23,17.851274 23,11.5 Z" }))), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.3333333,0,0,-1.3333333,0,30.666667)", id: "g10" }, /* @__PURE__ */ React.createElement("g", { id: "g12" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clipPath18)", id: "g14" }, /* @__PURE__ */ React.createElement("g", { id: "g20" }, /* @__PURE__ */ React.createElement("path", { id: "path22", style: {
  fill: "var(--fill-color)",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "M 22,11.5 C 22,5.70101 17.29899,1 11.5,1 V -1 C 18.403559,-1 24,4.596441 24,11.5 Z M 11.5,1 C 5.70101,1 1,5.70101 1,11.5 H -1 C -1,4.596441 4.596441,-1 11.5,-1 Z M 1,11.5 C 1,17.29899 5.70101,22 11.5,22 v 2 C 4.596441,24 -1,18.403559 -1,11.5 Z M 11.5,22 C 17.29899,22 22,17.29899 22,11.5 h 2 C 24,18.403559 18.403559,24 11.5,24 Z" }))))));
export default SvgRoundButtonEmpty;
