import currency from 'currency.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { jwtDecode } from 'jwt-decode';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { AppRadioButton } from 'src/components/radiobutton';

import { FinancingOption } from 'src/store/payment/models';

import { transformEnums } from 'src/utils/transformEnums';

import {
    StyledCustomLabel,
    StyledDataText,
    StyledFormControlLabel,
    StyledInstallments,
    StyledRadioGroup,
} from './financing-options.styled';

dayjs.extend(utc);

type Props = {
    financingOption: string;
    setFinancingOption: Dispatch<SetStateAction<string>>;
    options: any;
};

export const FinancingOptions = ({ options, financingOption, setFinancingOption }: Props) => {
    const handleSelectFinancingOptionChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setFinancingOption((event.target as HTMLInputElement).value);
        },
        [setFinancingOption]
    );

    const radioStyles = useMemo(() => {
        return { alignSelf: 'start' };
    }, []);

    const appRadio = useCallback(() => {
        return <AppRadioButton buttonColor="#e7e8e9" style={radioStyles} />;
    }, [radioStyles]);

    const customLabel = (
        value: string,
        isChecked: boolean,
        installments: { date: string; amount: number }[]
    ) => {
        const isDirectPayment = !installments;

        return (
            <>
                <StyledCustomLabel $isChecked={isChecked} $isDirectPayment={isDirectPayment}>
                    {value}
                </StyledCustomLabel>
                {isChecked && !isDirectPayment && (
                    <StyledInstallments>
                        {installments.map((installment, index) => {
                            return (
                                <StyledDataText key={index}>
                                    <span>{dayjs.utc(installment.date).local().format('MMM DD, YYYY')}</span>
                                    <span>Amount charged: {currency(installment.amount).format()}</span>
                                </StyledDataText>
                            );
                        })}
                    </StyledInstallments>
                )}
            </>
        );
    };

    return (
        <StyledRadioGroup
            aria-label="financing-options"
            name="radio-buttons-group"
            onChange={handleSelectFinancingOptionChange}
            value={financingOption}
        >
            {options.map((el: any, index: string) => {
                const _el: FinancingOption = jwtDecode(el);
                const isDirectPay = !_el.bnplMerchantId;

                let _label: string;
                const installments = _el.installments;

                if (isDirectPay) {
                    _label = `${currency(_el.totalPayment).format()} Direct Payment`;
                } else {
                    _label = `${currency(_el.installmentAmount).format()} ${transformEnums(
                        'PAYMENT_FREQUENCY',
                        _el.paymentFrequency
                    )} (x${_el.paymentsCount})`;
                }

                return (
                    <StyledFormControlLabel
                        control={appRadio()}
                        key={_el.firstPaymentAt + index}
                        label={customLabel(_label, financingOption === el, installments)}
                        value={el}
                    />
                );
            })}
        </StyledRadioGroup>
    );
};
