import { ENV } from 'src/configuration/constants';

import { ConfigEnv } from 'src/types/config.interface';

const config: ConfigEnv = {
    env: ENV.SANDBOX,
    firebaseConfig: {
        apiKey: 'AIzaSyCbiA2d1d2oZA5Gu6XgcXLDADLEPIqUG-I',
        authDomain: 'chargee-sandbox.firebaseapp.com',
        databaseURL: 'https://chargee-sandbox.firebaseio.com',
        projectId: 'chargee-sandbox',
        storageBucket: 'chargee-sandbox.appspot.com',
        messagingSenderId: '137527055164',
        appId: '1:137527055164:web:c670387e0faee724484439',
        measurementId: 'G-Z9R7ZW95QS',
    },
    siteId: 'chargee-sandbox',
    baseApiUrl: 'https://api-sandbox.chargee.co',
    fiServeWidgetBaseUrl: 'https://ais-ui.fiservapps.com',
    showBnpl: false,
    sentryConfig: {
        dsn: 'https://c6da10034243471c8169bbb62c329db6@o503848.ingest.sentry.io/5589529',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.1,
    },
    iOSStore: 'https://apps.apple.com/us/app/chargee-checkouts/id6444312135',
    googleStore: 'https://play.google.com/store/apps/details?id=co.chargee.app.stage',
    branchKey: 'key_test_jzkP5ZPn2a4A6OlYwiRVBknpyzhX1adO',
};

export default config;
