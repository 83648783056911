import { Alert } from '@mui/material';

import { StyledAlertWrapper } from 'src/components/alerts/alerts.styled';

interface AlertProps {
    alertType: 'error' | 'info' | 'success' | 'warning';
    message: string;
}

export const Alerts = ({ alertType, message }: AlertProps) => {
    return (
        <StyledAlertWrapper>
            <Alert severity={alertType}>{message.toString()}</Alert>
        </StyledAlertWrapper>
    );
};
