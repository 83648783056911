import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledBodySection = styled.div`
    margin-top: 11px;
    margin-bottom: 120px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const StyledInfoText = styled.div`
    line-height: ${units.p30}px;
    ${AppTypography.smallText};
    padding: 20px 10px;
`;
