import styled from '@emotion/styled';

import { AppTypography } from 'src/theme/app-typography';
import { units } from 'src/theme/units';

export const StyledTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledTitle = styled('h3', {
    shouldForwardProp: prop => prop !== '$isChargee',
})<{ $isChargee?: boolean }>`
    line-height: ${units.p30}px;
    ${props => (props.$isChargee ? AppTypography.chargeeRegularText : AppTypography.h3)};
`;

export const StyledSubTitle = styled.h4`
    line-height: ${units.p30}px;
    ${AppTypography.smallText};
`;
