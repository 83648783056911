import { useCallback } from 'react';
import Lottie from 'react-lottie';
import billmybankAnimationData from '@flavours/billmybank/assets/lottie/contact-guy.json';
import chargeeAnimationData from '@flavours/chargee/assets/lottie/contact-guy.json';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';
import {
    StyledButtonSection,
    StyledText,
    StyledTextSection,
    StyledTitle,
    StyledErrText,
} from './transaction-expired.styled';
import { TEXT_VARS } from 'src/i18n/en';

import { AppButton } from 'src/components/button';

import { getApplicationConfig } from 'src/configuration/config';
import { useAppRoute } from 'src/hooks/useAppRoute';

const { brandName } = getApplicationConfig();
const isBMB = brandName?.toLowerCase() === 'billmybank';

const options = {
    animationData: isBMB ? billmybankAnimationData : chargeeAnimationData,
    autoplay: true,
    loop: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const TransactionExpired = () => {
    const route = useAppRoute();

    const handleProceedBtnClick = useCallback(() => route('/'), [route]);

    return (
        <StyledPageWithPaddingContainer>
            <StyledTitle>
                {TEXT_VARS.TITLE.EXPIRED_SCREEN_START}
                <br />
                {TEXT_VARS.TITLE.EXPIRED_SCREEN}
            </StyledTitle>

            <Lottie options={options} />

            <StyledTextSection>
                <StyledErrText>{TEXT_VARS.ERRORS.TRANSACTIONS_EXPIRED}</StyledErrText>
                <StyledText>{TEXT_VARS.COMMON_TEXT.KINDLY_REQUEST}</StyledText>
            </StyledTextSection>
            {/* This button may be added in the near future (https://billmybank.atlassian.net/browse/BMB-468) */}
            {/* <StyledButtonSection>
                <AppButton appButtonType={'Continue'} onClick={handleProceedBtnClick}>
                    {TEXT_VARS.BUTTON.PROCEED}
                </AppButton>
            </StyledButtonSection> */}
        </StyledPageWithPaddingContainer>
    );
};
